import { Box, Typography } from '@mui/material';
import { NotesArticle, NotesArticleSummary } from '@spec/Notes';
import { Talent } from '@spec/Talent';
import { sortByKey } from '../../../lib/ArrayUtils';
import { useAuthorsNote } from '../../../queries/notes';
import { LinkButton, LinkButtonProps } from '../../ActionButtons';
import { FlexBox } from '../../FlexBox';
import { NavigateBeforeIcon, NavigateNextIcon } from '../../Icons';
import { TalentAvatar } from '../../TalentAvatar';
import { useAuthor } from '../Hooks';
import { getArticleUrl, getAuthorUrl } from '../urls';

export const ArticleNav: React.FC<{ article: NotesArticle }> = (props) => {
    const author = useAuthor(props.article);
    const articles = sortByKey(
        useAuthorsNote(author.hitonowaId).data?.articles ?? [],
        'publishedAt'
    );
    const [prev, next] = getAdjacentArticles(articles, props.article.id);
    const authorUrl = getAuthorUrl(author.hitonowaId);
    return (
        <FlexBox justifyContent="space-between" alignItems="flex-start">
            <ArticleCard article={prev} author={author} label="prev" />
            <NavButton to={authorUrl} sx={{ display: 'flex' }}>
                <FlexBox gap={1} flexWrap="nowrap" sx={{ wordBreak: 'break-all' }}>
                    <TalentAvatar size="small" talent={author} />
                    {author.hitonowaId}さんの記事一覧
                </FlexBox>
            </NavButton>
            <ArticleCard article={next} author={author} label="next" />
        </FlexBox>
    );
};

const NavButton: React.FC<LinkButtonProps> = (props) => {
    const { sx, ...rest } = props;
    return (
        <LinkButton
            variant="outlined"
            sx={{ ...sx, width: '200px', minHeight: '56px' }}
            {...rest}
        />
    );
};

const getAdjacentArticles = (
    articles: NotesArticleSummary[],
    currentId: number
): Array<NotesArticleSummary | null> => {
    const index = articles.findIndex((v) => v.id === currentId);

    if (index === -1) {
        return [null, null];
    }

    const prev = index > 0 ? articles[index - 1] : null;
    const next = index < articles.length - 1 ? articles[index + 1] : null;

    return [prev, next];
};

const ArticleCard: React.FC<{
    article: NotesArticleSummary | null;
    author: Talent;
    label: 'prev' | 'next';
}> = (props) => {
    if (props.article === null) {
        return <Box width="200px" />;
    }
    return (
        <NavButton
            to={getArticleUrl(props.article.id, props.author.hitonowaId)}
            sx={{ display: 'block' }}
        >
            <Label label={props.label} />
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {props.article.title}
            </Typography>
        </NavButton>
    );
};

const Label: React.FC<{ label: 'prev' | 'next' }> = (props) => {
    const { Icon, text } = {
        prev: { Icon: NavigateBeforeIcon, text: '前の記事' },
        next: { Icon: NavigateNextIcon, text: '次の記事' },
    }[props.label];
    return (
        <FlexBox my={0.5}>
            <Icon color="disabled" fontSize="small" />
            <Typography variant="caption">{text}</Typography>
        </FlexBox>
    );
};
