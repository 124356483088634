import {
    Box,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    type SxProps,
} from '@mui/material';
import type { ScreenedProfileImage } from '@spec/profileImage';
import type { Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import type { ReactNode } from 'react';
import { getBaseTeamName, getCorporate } from '../../domains/Organization';
import { fullName } from '../../domains/Talent';
import { findById } from '../../lib/ArrayUtils';
import { useScreenedProfileImages } from '../../queries/profileImage';
import { getTalentUrl } from '../../Routes';
import { LinkButton } from '../ActionButtons';
import { useTalentsContext, useTeamsContext } from '../Context';
import { FlexBox } from '../FlexBox';
import { CancelIcon, CheckBoxIcon, DeleteIcon, KeyboardDoubleArrowRightIcon } from '../Icons';
import { NoItems } from '../NoItems';
import { Pager, sliceItems } from '../Pager';
import { TalentAvatar, avatarSize } from '../TalentAvatar';
import { WaitQuery } from '../WaitLoading';
import { useFilterFormContext } from './Context';
import {
    detectScreeningStatus,
    filterScreenedImage,
    filterTalents,
    type ScreeningStatus,
} from './domains';
import { PreviewAvatar } from './PreviewAvatar';
import { ScreeningFilterForm, StatusFilterForm } from './ScreeningFilterForm';

export const ScreeningHistory = () => {
    const maybeScreenedImages = useScreenedProfileImages();
    return (
        <Box>
            <ScreeningFilterForm />
            <Box mt={2}>
                <StatusFilterForm />
            </Box>
            <WaitQuery query={maybeScreenedImages}>
                {({ data }) => <ScreenedProfileImages images={data} />}
            </WaitQuery>
        </Box>
    );
};

const ScreenedProfileImages = (props: { images: ScreenedProfileImage[] }) => {
    const { availableTalents } = useTalentsContext();
    const { teams } = useTeamsContext();
    const { page, setPage, condition } = useFilterFormContext();
    const itemsPerPage = 10;
    const candidates = new Set(filterTalents(availableTalents, teams, condition).map((v) => v.id));
    const filteredImages = props.images
        .filter((v) => candidates.has(v.talentId))
        .filter((v) => filterScreenedImage(v, condition));

    if (filteredImages.length === 0) {
        return <NoItems mt={8}>審査済みのプロフィール画像はありません</NoItems>;
    }
    const slicedImages = sliceItems(filteredImages, page, itemsPerPage);
    return (
        <Box mt={2}>
            <Pager
                amount={filteredImages.length}
                current={page}
                perItems={itemsPerPage}
                setPage={setPage}
            />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>審査結果</TableCell>
                        <TableCell>画像</TableCell>
                        <TableCell>アップロード日時</TableCell>
                        <TableCell>対象者</TableCell>
                        <TableCell>アップロード者</TableCell>
                        <TableCell>審査者</TableCell>
                        <TableCell>審査日時</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {slicedImages.map((v) => (
                        <ImageRow key={v.id} image={v} />
                    ))}
                </TableBody>
            </Table>
            <Pager
                amount={filteredImages.length}
                current={page}
                perItems={itemsPerPage}
                setPage={setPage}
            />
        </Box>
    );
};

const ImageRow = (props: { image: ScreenedProfileImage }) => {
    const { talents } = useTalentsContext();
    const v = props.image;
    const talent = findById(v.talentId, talents);
    const uploadTalent = findById(v.uploadTalentId, talents);
    const reviewer = findById(v.reviewerTalentId, talents);
    const sxProps: SxProps = v.isApproved ? {} : { backgroundColor: '#eee' };
    return (
        <TableRow sx={sxProps}>
            <ScreeningResultCell image={v} />
            <TableCell sx={{ width: avatarSize.large }}>
                <PreviewAvatar image={v} size="mediumLarge" />
            </TableCell>
            <TableCell>{dayjs(v.uploadedAt).format('YYYY-MM-DD HH:mm')}</TableCell>
            <TableCell>
                <TalentName talent={talent} />
            </TableCell>
            <TableCell>
                {v.talentId === v.uploadTalentId ? '本人' : fullName(uploadTalent)}
            </TableCell>
            <TableCell>{fullName(reviewer)}</TableCell>
            <TableCell>{dayjs(v.reviewedAt).format('YYYY-MM-DD HH:mm')}</TableCell>
        </TableRow>
    );
};

const ScreeningResultCell = (props: { image: ScreenedProfileImage }) => {
    const candidates: Record<ScreeningStatus, { icon: ReactNode; text: string }> = {
        approved: { icon: <CheckBoxIcon color="success" />, text: 'OK' },
        rejected: { icon: <CancelIcon color="error" />, text: 'NG' },
        withdrawn: { icon: <DeleteIcon color="info" />, text: '取り下げ' },
    };
    const status = candidates[detectScreeningStatus(props.image)];
    return (
        <TableCell sx={{ width: '4rem' }}>
            <FlexBox flexWrap="nowrap" gap={0.5}>
                {status.icon}
                {status.text}
            </FlexBox>
        </TableCell>
    );
};

const TalentName = (props: { talent: Talent }) => {
    const { teams } = useTeamsContext();
    const corporate = getCorporate(props.talent, teams);
    const baseTeamName = getBaseTeamName(props.talent, teams);
    const talent = props.talent;
    return (
        <Tooltip
            components={{ Tooltip: Box }}
            placement="top"
            title={
                <Card square={false}>
                    <CardContent>
                        <Typography variant="body2">{corporate.name}</Typography>
                        <Typography variant="body2">{baseTeamName}</Typography>
                        <Typography variant="body2">{talent.employment.employeeCode}</Typography>
                        <Typography>{fullName(talent)}</Typography>
                        <TalentAvatar size="large" talent={talent} />
                        <Box mt={1}>
                            <LinkButton
                                startIcon={<KeyboardDoubleArrowRightIcon />}
                                to={getTalentUrl(talent.employment.employeeCode)}
                            >
                                プロフィールを見る
                            </LinkButton>
                        </Box>
                    </CardContent>
                </Card>
            }
        >
            <Box>
                <Typography variant="inherit">{corporate.name}</Typography>
                <Typography variant="inherit">{baseTeamName}</Typography>
                <Typography variant="inherit">{fullName(props.talent)}</Typography>
            </Box>
        </Tooltip>
    );
};
