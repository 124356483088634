import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMeContext, useUploadProfileImage } from '../../../queries/me';
import { LinkButton } from '../../ActionButtons';
import { useAvatarUpdateContext } from '../../Context';
import { NavigateBeforeIcon } from '../../Icons';
import { SubTitle } from '../../PageTitle';
import { UploadProfileImage } from '../UploadProfileImage';
import { ProfileImageGuide } from './ProfileImageGuide';

export const ProfileImage = () => {
    const { me, profileImage } = useMeContext();
    const { refresh, setUploaded } = useAvatarUpdateContext();
    const mutation = useUploadProfileImage();
    const navigate = useNavigate();
    const location = useLocation();
    const imagePath = (() => {
        switch (new URLSearchParams(location.search).get('base')) {
            case 'current':
                return me.profileImagePath;
            case 'pending':
                return profileImage?.pending?.imagePath ?? null;
            case 'lastScreened':
                return profileImage?.lastScreened?.imagePath ?? null;
            default:
                return null;
        }
    })();
    return (
        <Box>
            <LinkButton to=".." startIcon={<NavigateBeforeIcon />}>
                プロフィールの編集画面に戻る
            </LinkButton>
            <Box mt={2}>
                <SubTitle title="プロフィール画像" />
            </Box>
            <Box>
                <Box my={2}>
                    <ProfileImageGuide />
                </Box>
                <UploadProfileImage
                    imagePath={imagePath}
                    onUpload={(blob) =>
                        mutation.mutateAsync(blob).then(() => {
                            refresh();
                            setUploaded(true);
                            navigate('..');
                        })
                    }
                />
            </Box>
        </Box>
    );
};
