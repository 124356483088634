import React, { ReactNode } from 'react';
import { CheckCircleIcon, DownloadIcon, PublishIcon } from '../../../Icons';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineContentProps,
    TimelineDot,
    TimelineItem,
    timelineItemClasses,
    TimelineSeparator,
} from '@mui/lab';
import { Box, Button, Divider, Typography } from '@mui/material';
import { canPublish, getStatus, TodoItemStatus } from '../../../../domains/Todo';
import { FlexBox } from '../../../FlexBox';
import { getStatusLabel } from '../Contract';
import { DialogMode, useDialogContext, useTodoItemContext } from './Context';

export const ItemStatusFlow: React.FC = () => {
    const { item, assignments } = useTodoItemContext();
    const status = getStatus(item);
    return (
        <Box>
            <Timeline
                sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                    },
                }}
            >
                <StatusItem
                    status="draft"
                    active={status === 'draft'}
                    actions={[
                        {
                            icon: <PublishIcon />,
                            dialogMode: 'PublishTodoItem',
                            label: '公開する',
                        },
                    ]}
                    disabled={!canPublish(item, assignments)}
                    footnote={
                        canPublish(item, assignments) ? null : (
                            <Typography variant="body2" color="error">
                                ※公開準備ができていません
                            </Typography>
                        )
                    }
                >
                    <Typography variant="body2">
                        対象者を追加・除外してもSlack通知されず、
                    </Typography>
                    <Typography variant="body2">
                        画面上の「やること一覧」にも表示されません。
                    </Typography>
                    <Typography mt={1} variant="body2">
                        公開するとその時点の対象者全員にSlack通知されます。
                    </Typography>
                </StatusItem>
                <StatusItem
                    status="public"
                    active={status === 'public'}
                    actions={[
                        {
                            icon: <DownloadIcon />,
                            dialogMode: 'UnpublishTodoItem',
                            label: '準備中に戻す',
                        },
                        {
                            icon: <CheckCircleIcon />,
                            dialogMode: 'CompleteTodoItem',
                            label: '公開終了する',
                        },
                    ]}
                >
                    <Typography variant="body2">
                        対象者の「やること一覧」に表示されます。
                    </Typography>
                    <Typography mt={1} variant="body2">
                        対象者を追加・除外したり、やることの状態を変更すると
                    </Typography>
                    <Typography variant="body2">対象者全員にSlack通知されます。</Typography>
                </StatusItem>
                <StatusItem
                    status="finished"
                    active={status === 'finished'}
                    actions={[
                        {
                            icon: <PublishIcon />,
                            dialogMode: 'PublishTodoItem',
                            label: 'もう一度公開する',
                        },
                    ]}
                >
                    <Typography variant="body2">
                        対象者の「やること一覧」に表示されなくなります。
                    </Typography>
                    <Typography mt={1} variant="body2">
                        公開済みに戻すと未完了の対象者全員にSlack通知されます。
                    </Typography>
                </StatusItem>
            </Timeline>
        </Box>
    );
};

interface ActionProps {
    icon: ReactNode;
    dialogMode: DialogMode;
    label: string;
}

const StatusItem: React.FC<{
    status: TodoItemStatus;
    active: boolean;
    children: ReactNode;
    actions: ActionProps[];
    disabled?: boolean;
    footnote?: ReactNode;
}> = (props) => {
    const { setDialogMode } = useDialogContext();
    const contentProps: TimelineContentProps = props.active ? {} : { color: 'grey' };
    const { color, label } = getStatusLabel(props.status);
    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot color={props.active ? color : 'grey'} />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent {...contentProps}>
                <FlexBox gap={1}>
                    <Typography>{label}</Typography>
                    {props.active === true && (
                        <Typography variant="body2" color="secondary" fontWeight={700}>
                            ← イマココ
                        </Typography>
                    )}
                </FlexBox>
                <Box mb={0.5}>
                    <Divider />
                </Box>
                {props.children}
                <FlexBox mt={1.5} mb={2} gap={2}>
                    {props.actions.map((v) => (
                        <Button
                            key={v.dialogMode}
                            disabled={props.disabled || !props.active}
                            size="small"
                            variant="outlined"
                            startIcon={v.icon}
                            onClick={() => setDialogMode(v.dialogMode)}
                        >
                            {v.label}
                        </Button>
                    ))}
                </FlexBox>
                {props.footnote !== undefined && props.footnote}
            </TimelineContent>
        </TimelineItem>
    );
};
