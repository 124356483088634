import { Avatar, Box, Chip, type ChipProps } from '@mui/material';
import type { ProfileImage } from '@spec/profileImage';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { useEffect } from 'react';
import { firebaseApp } from '../../App';
import { reportError } from '../../Errors';
import { useRefState } from '../../lib/AsyncResource';
import { avatarSize, type AvatarSize } from '../TalentAvatar';

export const PreviewAvatar = (props: {
    image: ProfileImage;
    size: AvatarSize;
    uploaderChip?: boolean;
}) => {
    const [imageUrl, setImageUrl] = useRefState<string | null>(null);
    useEffect(() => {
        getDownloadURL(ref(getStorage(firebaseApp), `${props.image.imagePath}_m`))
            .then(setImageUrl)
            .catch((e) => {
                reportError(e);
                setImageUrl(null);
            });
    }, [props.image.imagePath, setImageUrl]);
    const chipProps: ChipProps =
        props.image.talentId === props.image.uploadTalentId
            ? { label: 'SELF', color: 'secondary' }
            : { label: 'HR', color: 'primary' };
    return (
        <Box sx={{ position: 'relative', width: avatarSize[props.size] }}>
            <Avatar
                sx={{
                    width: avatarSize[props.size],
                    height: avatarSize[props.size],
                }}
                variant="rounded"
                src={imageUrl ?? undefined}
            />
            {props.uploaderChip === true && (
                <Chip {...chipProps} sx={{ position: 'absolute', right: 2, top: 2 }} />
            )}
        </Box>
    );
};
