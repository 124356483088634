import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    Typography,
} from '@mui/material';
import type { ProfileImage } from '@spec/profileImage';
import type { Talent, TalentId } from '@spec/Talent';
import dayjs from 'dayjs';
import { useState } from 'react';
import { getBaseTeamName, getCorporate } from '../../domains/Organization';
import { fullName } from '../../domains/Talent';
import { findById, sortByKey } from '../../lib/ArrayUtils';
import { useScreeningProfileImages, useUnscreenedProfileImages } from '../../queries/profileImage';
import { getTalentUrl } from '../../Routes';
import { useTalentsContext, useTeamsContext } from '../Context';
import { FlexBox } from '../FlexBox';
import { CheckIcon } from '../Icons';
import { NoItems } from '../NoItems';
import { Pager } from '../Pager';
import { RouterLink } from '../RouterLink';
import { TalentAvatar } from '../TalentAvatar';
import { WaitQuery } from '../WaitLoading';
import { useFilterFormContext } from './Context';
import { filterTalents } from './domains';
import { PreviewAvatar } from './PreviewAvatar';
import { RejectAllButton } from './RejectAllButton';
import { ScreeningFilterForm } from './ScreeningFilterForm';

export const ProfileImageScreening = () => {
    const maybeUnscreenedImages = useUnscreenedProfileImages();
    return (
        <Box>
            <ScreeningFilterForm />
            <WaitQuery query={maybeUnscreenedImages}>
                {({ data }) => <GroupedProfileImages images={data} />}
            </WaitQuery>
        </Box>
    );
};

const GroupedProfileImages = (props: { images: ProfileImage[] }) => {
    const { availableTalents } = useTalentsContext();
    const { teams } = useTeamsContext();
    const { page, setPage, condition } = useFilterFormContext();
    const itemsPerPage = 10;
    const candidates = new Set(filterTalents(availableTalents, teams, condition).map((v) => v.id));
    const groupedImages = Map.groupBy(
        sortByKey(
            props.images.filter((v) => candidates.has(v.talentId)),
            'uploadedAt',
            'desc'
        ),
        (v) => v.talentId
    );
    const talentCount = groupedImages.size;
    const entries = [...groupedImages.entries()];
    const currentEntries = entries.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    if (currentEntries.length === 0) {
        return <NoItems mt={8}>審査待ちのプロフィール画像はありません</NoItems>;
    }
    return (
        <Box mt={2}>
            <Pager amount={talentCount} current={page} perItems={itemsPerPage} setPage={setPage} />
            {currentEntries.map(([talentId, images]) => (
                <TalentImageCard key={talentId} talentId={Number(talentId)} images={images ?? []} />
            ))}
            <Pager amount={talentCount} current={page} perItems={itemsPerPage} setPage={setPage} />
        </Box>
    );
};

const TalentImageCard = (props: { talentId: TalentId; images: ProfileImage[] }) => {
    const { talents } = useTalentsContext();
    const { teams } = useTeamsContext();
    const talent = findById(props.talentId, talents);
    const corporate = getCorporate(talent, teams);
    const baseTeamName = getBaseTeamName(talent, teams);
    const mutation = useScreeningProfileImages(props.images);
    return (
        <Card>
            <CardContent>
                <FlexBox gap={2}>
                    <FlexBox gap={1}>
                        <Typography>{talent.employment.employeeCode}</Typography>
                        <RouterLink to={getTalentUrl(talent.employment.employeeCode)}>
                            <Typography>{fullName(talent)}</Typography>
                        </RouterLink>
                    </FlexBox>
                    <FlexBox gap={1}>
                        <Typography variant="body2">{corporate.name}</Typography>
                        <Typography variant="body2">{baseTeamName}</Typography>
                        <Typography variant="body2">
                            {talent.employment.employmentStatus}
                        </Typography>
                    </FlexBox>
                </FlexBox>
                <FlexBox mt={1} alignItems="flex-start" flexWrap="nowrap" gap={2}>
                    <Box>
                        <Typography mb={0.5} variant="body2">
                            現在の画像
                        </Typography>
                        <TalentAvatar talent={talent} size="large" />
                    </Box>
                    <Divider orientation="vertical" flexItem />
                    <Box>
                        <Typography variant="body2">審査待ちの画像</Typography>
                        <FlexBox mt={0.5} gap={4}>
                            {props.images.map((image) => (
                                <ProfileImageItem
                                    key={image.id}
                                    image={image}
                                    images={props.images}
                                    talent={talent}
                                    uploadTalent={findById(image.uploadTalentId, talents)}
                                    mutation={mutation}
                                />
                            ))}
                        </FlexBox>
                        <Box mt={2}>
                            <RejectAllButton
                                talent={talent}
                                images={props.images}
                                disabled={mutation.isPending}
                                onSubmit={() => mutation.mutate(null)}
                            />
                        </Box>
                    </Box>
                </FlexBox>
            </CardContent>
        </Card>
    );
};

const ProfileImageItem = (props: {
    image: ProfileImage;
    images: ProfileImage[];
    talent: Talent;
    uploadTalent: Talent;
    mutation: ReturnType<typeof useScreeningProfileImages>;
}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleCloseDialog = () => {
        setDialogOpen(false);
        props.mutation.reset();
    };
    const warningMessage =
        props.images.length > 1 ? (
            <Alert severity="warning" sx={{ mt: 2 }}>
                他の審査待ち画像{props.images.length - 1}枚は不採用になります
            </Alert>
        ) : null;
    const infoMessage =
        props.talent.profileImagePath === props.image.imagePath
            ? '既にこの画像が使用されているため、本人にはSlack通知されません'
            : '採用するとプロフィール画像が更新され、本人にSlack通知が飛びます';
    return (
        <Stack textAlign="center" spacing={1}>
            <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
                <DialogTitle>プロフィール画像の審査</DialogTitle>
                <DialogContent>
                    この画像を{fullName(props.talent)}さんのプロフィール画像として採用しますか？
                    <FlexBox mt={1} justifyContent="center">
                        <PreviewAvatar image={props.image} size="xlarge" />
                    </FlexBox>
                    <Alert severity="info" sx={{ mt: 2 }}>
                        {infoMessage}
                    </Alert>
                    {warningMessage}
                    {props.mutation.isError && (
                        <Alert severity="error" sx={{ mt: 2 }}>
                            審査に失敗しました
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button
                        onClick={handleCloseDialog}
                        size="medium"
                        variant="outlined"
                        tabIndex={2}
                    >
                        キャンセル
                    </Button>
                    <Box flexGrow={1} textAlign="right">
                        <Button
                            onClick={async () => {
                                await props.mutation
                                    .mutateAsync(props.image.id)
                                    .then(() => setDialogOpen(false));
                            }}
                            size="medium"
                            variant="contained"
                            color="primary"
                            startIcon={<CheckIcon />}
                            disabled={props.mutation.isPending}
                            tabIndex={1}
                        >
                            採用する
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Box display="flex" gap={1}>
                <PreviewAvatar image={props.image} size="xlarge" uploaderChip />
                <PreviewAvatar image={props.image} size="large" />
                <Box>
                    <PreviewAvatar image={props.image} size="mediumLarge" />
                    <Box display="flex" mt={0.5} gap={1}>
                        <PreviewAvatar image={props.image} size="medium" />
                        <PreviewAvatar image={props.image} size="small" />
                    </Box>
                </Box>
            </Box>
            <FlexBox gap={1} justifyContent="center">
                <Typography variant="body2">
                    uploaded :{dayjs(props.image.uploadedAt).format(' YYYY-MM-DD HH:mm')}
                </Typography>
                <Typography variant="body2">{fullName(props.uploadTalent)}</Typography>
            </FlexBox>
            <Button
                variant="contained"
                color="primary"
                startIcon={<CheckIcon />}
                onClick={() => setDialogOpen(true)}
            >
                採用する
            </Button>
        </Stack>
    );
};
