import { Box } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import { RoutingPattern } from '../../Routes';
import { LinkButton } from '../ActionButtons';
import { GroupsIcon, NavigateBeforeIcon } from '../Icons';
import { PageTitle } from '../PageTitle';
import { RequirePrivilege } from '../RequirePrivilege';

export function Layout() {
    const location = useLocation();
    return (
        <RequirePrivilege fn={(grants) => grants.showFullPersonality || grants.showHrDashboard}>
            <PageTitle icon={GroupsIcon} title="HRダッシュボード" />
            {location.pathname !== RoutingPattern.admin && (
                <Box my={2}>
                    <LinkButton to={RoutingPattern.admin} startIcon={<NavigateBeforeIcon />}>
                        HRダッシュボードに戻る
                    </LinkButton>
                </Box>
            )}
            <Outlet />
        </RequirePrivilege>
    );
}
