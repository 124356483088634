export const queryKey = {
    me: 'me',
    apiReferences: 'apiReferences',
    grades: 'grades',
    grants: 'grants',

    teams: 'teams',

    circles: 'circles',
    circleTerms: 'circleTerms',
    circleMembers: 'circleMembers',

    surveys: 'surveys',
    surveyPeriod: 'surveyPeriod',
    surveyTalent: 'surveyTalent',

    workplaces: 'workplaces',

    companyValues: 'companyValues',
    valueFeedbacks: 'valueFeedbacks',
    talentFeedbacks: 'talentFeedbacks',
    valueFeedbackStats: 'valueFeedbackStats',
    valueFeedbackScores: 'valueFeedbackScores',
    valueFeedbackSelections: 'valueFeedbackSelections',

    talents: 'talents',
    leaveOfAbsences: 'leaveOfAbsences',
    sabbaticalLeaves: 'sabbaticalLeaves',
    nextSabbaticalLeaves: 'nextSabbaticalLeaves',

    todoItems: 'todoItems',
    assignedTodoItems: 'assignedTodoItems',
    todoCategories: 'todoCategories',

    notes: 'notes',

    jobs: 'jobs',

    slack: 'slack',

    notifications: 'notifications',

    operationLogs: 'operationLogs',

    humanCapital: 'humanCapital',

    profileImages: 'profileImages',
};
