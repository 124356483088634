import { Alert, Box, Card, CardContent, Divider, Typography, useTheme } from '@mui/material';
import { Talent, UpdateMeRequest } from '@spec/Talent';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTalentUrl, RoutingPattern } from '../../../Routes';
import { FormTheme } from '../../../Theme';
import { createHitonowaIdValidator } from '../../../domains/Talent';
import { Form, FormText, FormTextField, useForm, useFormText } from '../../../lib/Form';
import { extractGitHubId, toLowerCase, trim, zenToHan } from '../../../lib/Form/Filters';
import { githubId, maxLength, required, telephoneNumber, url } from '../../../lib/Form/Validators';
import { queryToArray } from '../../../queries';
import { useMeContext, useUpdateMe } from '../../../queries/me';
import { useTalents } from '../../../queries/talent';
import { ActionContainer, LinkButton } from '../../ActionButtons';
import { useAvatarUpdateContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { FootNote } from '../../FootNote';
import { NavigateBeforeIcon } from '../../Icons';
import { SubTitle } from '../../PageTitle';
import { RouterLink } from '../../RouterLink';
import { SubmitButton } from '../../SubmitButton';
import { HitonowaId } from '../HitonowaId';
import { PersonalEventHistory } from './PersonalEventHistory';
import { ProfileImageList } from './ProfileImageList';
import { SlackChannelSelector } from './SlackChannelSelector';

export const EditMe: React.FC = () => {
    const { me } = useMeContext();
    if (me.personalEventHistory === undefined) {
        throw Error('Detect personalEventHistory as undefined');
    }
    return (
        <>
            <EditForm talent={me} />
            <Box mt={4}>
                <PersonalEventHistory personalEvents={me.personalEventHistory} />
            </Box>
        </>
    );
};

interface Elements {
    hitonowaId: string;
    nicknames: string;
    slackChannelId: string;
    tel: string;
    selfIntroductionUrl: string;
    githubId: string;
}

const toUpdateMeRequest = (form: Form<Elements>): UpdateMeRequest => {
    const args = form.serialize();
    return {
        ...args,
        githubId: args.githubId === '' ? null : args.githubId,
        slackChannelId: args.slackChannelId === '' ? null : args.slackChannelId,
    };
};

const EditForm: React.FC<{ talent: Talent }> = (props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const talents = useTalents();
    const otherTalents = queryToArray(talents).filter((v) => v.id !== props.talent.id);
    const form = useForm<Elements>({
        hitonowaId: useFormText(
            props.talent.hitonowaId ?? '',
            [required, createHitonowaIdValidator(otherTalents)],
            {
                onChange: [toLowerCase],
            }
        ),
        nicknames: useFormText(props.talent.nicknames),
        slackChannelId: useFormText(props.talent.slackChannelId ?? ''),
        tel: useFormText(props.talent.tel, [telephoneNumber], {
            onBlur: [trim, zenToHan],
        }),
        selfIntroductionUrl: useFormText(props.talent.selfIntroductionUrl, [maxLength(255), url]),
        githubId: useFormText(props.talent.githubId ?? '', [githubId], {
            onChange: [trim, extractGitHubId],
        }),
    });
    const mutation = useUpdateMe();
    return (
        <Box>
            <Box mb={2}>
                <LinkButton
                    to={getTalentUrl(props.talent.employment.employeeCode)}
                    startIcon={<NavigateBeforeIcon />}
                >
                    プロフィールに戻る
                </LinkButton>
            </Box>
            <Box>
                <SubTitle title="プロフィール画像" />
                <Box mt={2}>
                    <ProfileImageList />
                </Box>
                <UploadedAlert />
            </Box>
            <Box my={2}>
                <Divider />
            </Box>
            <FormTheme>
                <Typography variant="subtitle1" color="primary">
                    基本プロフィール
                </Typography>
                <Card square={false}>
                    <CardContent>
                        <Box m={1}>
                            <Box>
                                <Typography>ヒトノワID（必須）</Typography>
                                <FlexBox gap={theme.spacing(2)}>
                                    <Box flexBasis="50%">
                                        <FormTextField fullWidth formText={form.hitonowaId} />
                                    </Box>
                                    <Box flexBasis="50%">
                                        <HitonowaIdError hitonowaId={form.hitonowaId} />
                                    </Box>
                                </FlexBox>
                                <FootNote>
                                    ヒトノワでの個人URLに反映されます。半角英数字（小文字）とハイフンおよびアンダースコアで32文字まで利用できます。他の人が既に使っているIDは使えません。
                                </FootNote>
                            </Box>
                            <Box mt={2}>
                                <Typography>ニックネーム</Typography>
                                <FormTextField fullWidth formText={form.nicknames} />
                                <FootNote>
                                    このシステム上では、ここに記載した名前でもあなたのことを検索できるようになります。複数書きたい場合は適当に区切って書いてください。
                                </FootNote>
                            </Box>
                            <Box mt={2}>
                                <Typography>Slackの個人チャンネル</Typography>
                                <SlackChannelSelector formText={form.slackChannelId} />
                            </Box>
                            <Box mt={2}>
                                <Typography>GitHub ID</Typography>
                                <FormTextField fullWidth formText={form.githubId} />
                                {form.githubId.error !== null && (
                                    <Typography mt={0.5} variant="body2" color="error">
                                        GitHub IDを識別できません。
                                    </Typography>
                                )}
                                <FootNote>
                                    GitHubの右上アイコンをクリックして出てくる{'Your profile'}
                                    を開いて、そのURLを貼り付けてください。
                                </FootNote>
                                <Box mt={0.5} ml={1}>
                                    <img src="/images/github_profile_link.png" width="240" />
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <Typography>自己紹介URL</Typography>
                                <FormTextField fullWidth formText={form.selfIntroductionUrl} />
                                <FootNote>
                                    自己紹介は
                                    <RouterLink underline="always" to={RoutingPattern.notes}>
                                        ノート
                                    </RouterLink>
                                    に記事を書いてピン留めするのがおすすめですが、インターネット上の公開ページなどにリンクしたい場合はここにURLを入れてください。
                                </FootNote>
                            </Box>
                            <Box mt={2}>
                                <Typography>tel</Typography>
                                <FormTextField fullWidth formText={form.tel} />
                                <FootNote>
                                    会社から貸与されている携帯電話の番号などを掲載できます。
                                </FootNote>
                            </Box>
                            <ActionContainer mt={2}>
                                <SubmitButton
                                    fullWidth
                                    disabled={!form.canSubmit}
                                    inProgress={form.inProgress}
                                    onClick={() => {
                                        form.send(
                                            mutation
                                                .mutateAsync(toUpdateMeRequest(form))
                                                .then(() => {
                                                    navigate(
                                                        getTalentUrl(
                                                            props.talent.employment.employeeCode
                                                        )
                                                    );
                                                })
                                        );
                                    }}
                                >
                                    基本プロフィールを更新する
                                </SubmitButton>
                            </ActionContainer>
                        </Box>
                    </CardContent>
                </Card>
            </FormTheme>
            <Box mt={2}>
                {form.error && <Alert severity="error">プロフィールの更新に失敗しました</Alert>}
            </Box>
        </Box>
    );
};

const UploadedAlert = () => {
    const { uploaded, setUploaded } = useAvatarUpdateContext();

    // Keep showing alert regardless of strict mode
    const [showAlert] = useState(uploaded);

    // Reset uploaded flag on unmount
    useEffect(() => {
        return () => {
            setUploaded(false);
        };
    }, [setUploaded]);

    if (showAlert === false) {
        return null;
    }
    return (
        <Box mt={2}>
            <Alert severity="success">プロフィール画像をアップロードしました</Alert>
        </Box>
    );
};

const HitonowaIdError: React.FC<{ hitonowaId: FormText }> = ({ hitonowaId }) => {
    const theme = useTheme();
    switch (hitonowaId.error) {
        case null:
            return null;
        case 'required':
            return (
                <Typography variant="body2" color="error">
                    省略できません
                </Typography>
            );
        case 'exists':
            return (
                <FlexBox gap={theme.spacing(1)}>
                    <HitonowaId hitonowaId={hitonowaId.value} enableLink />
                    <Typography variant="body2" color="error">
                        は既に利用されています
                    </Typography>
                </FlexBox>
            );
        default:
            return (
                <Typography variant="body2" color="error">
                    規定のフォーマットに従って入力してください
                </Typography>
            );
    }
};
