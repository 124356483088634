import { DateRangeIcon, LanguageIcon } from '../Icons';
import { Box, MenuItem, TextField, Typography } from '@mui/material';
import React from 'react';
import { FlexBox } from '../FlexBox';
import { IconText } from '../IconText';
import { LogType, ReadWrite } from './Contract';

const thisYear = new Date().getFullYear();
const years: number[] = [];
for (let i = 2020; i <= thisYear; i++) {
    years.push(i);
}
const months: number[] = [];
for (let i = 1; i <= 12; i++) {
    months.push(i);
}

export const FilterForm: React.FC<
    React.PropsWithChildren<{
        year: number;
        setYear: (v: number) => void;
        month: number;
        setMonth: (v: number) => void;
        logType: LogType;
        setLogType: (v: LogType) => void;
        readWrite: ReadWrite;
        setReadWrite: (v: ReadWrite) => void;
        path: string;
        setPath: (v: string) => void;
    }>
> = (props) => {
    return (
        <FlexBox>
            <Box>
                <IconText icon={DateRangeIcon}>対象月</IconText>
            </Box>
            <Box ml={1}>
                <TextField
                    select
                    value={props.year}
                    onChange={(e) => {
                        const y = Number(e.target.value);
                        if (!Number.isNaN(y)) {
                            props.setYear(y);
                        }
                    }}
                >
                    {years.map((v) => (
                        <MenuItem key={v} value={v}>
                            {v}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box ml={0.5}>
                <Typography variant="body2">年</Typography>
            </Box>
            <Box ml={1}>
                <TextField
                    select
                    value={props.month}
                    onChange={(e) => {
                        const y = Number(e.target.value);
                        if (!Number.isNaN(y)) {
                            props.setMonth(y);
                        }
                    }}
                >
                    {months.map((v) => (
                        <MenuItem key={v} value={v}>
                            {v}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>
            <Box ml={0.5}>
                <Typography variant="body2">月</Typography>
            </Box>
            <Box ml={3}>
                <IconText icon={LanguageIcon}>URL</IconText>
            </Box>
            <Box ml={1}>
                <TextField
                    sx={{ width: '16rem' }}
                    value={props.path}
                    onChange={(e) => props.setPath(e.target.value)}
                />
            </Box>
            <Box ml={3}>
                <Typography variant="body2">ログ種別</Typography>
            </Box>
            <Box ml={1}>
                <TextField
                    select
                    value={props.logType}
                    onChange={(e) => props.setLogType(e.target.value as LogType)}
                >
                    <MenuItem value="ALL">すべて</MenuItem>
                    <MenuItem value="SUCCEEDED">正常系のみ</MenuItem>
                    <MenuItem value="ERROR">エラーのみ</MenuItem>
                </TextField>
            </Box>
            <Box ml={1}>
                <TextField
                    select
                    value={props.readWrite}
                    onChange={(e) => props.setReadWrite(e.target.value as ReadWrite)}
                >
                    <MenuItem value="BOTH">読み書き</MenuItem>
                    <MenuItem value="READ">読み込み</MenuItem>
                    <MenuItem value="WRITE">書き込み</MenuItem>
                </TextField>
            </Box>
        </FlexBox>
    );
};
