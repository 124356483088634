import {
    Alert,
    AlertTitle,
    List,
    ListItem,
    listItemClasses,
    ListItemText,
    listItemTextClasses,
    Typography,
} from '@mui/material';
import { ExternalLink } from '../../RouterLink';

export const ProfileImageGuide = () => {
    return (
        <Alert severity="info">
            <AlertTitle>
                プロフィール画像は第三者から見て本人だと確認できる写真を設定してください
            </AlertTitle>
            <List
                dense
                disablePadding
                sx={{
                    listStyleType: 'disc',
                    pl: 2,
                    [`& .${listItemClasses.root}`]: { p: 0, display: 'list-item' },
                    [`& .${listItemTextClasses.root}`]: { mb: 0 },
                }}
            >
                <ListItem>
                    <ListItemText primary="正面を向いて顔全体（両目、鼻、口）が写り、明確に識別できること" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="バストアップで、頭頂部から顎までの高さが画像の高さの1/4以上とすること" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="顔の一部が隠れていないこと（マスク、サングラス、帽子、手など）" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="カラーで本人が鮮明に確認でき、極端な加工がされていないこと" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="本人以外の人が写っていないこと 5年以内に撮られた写真であること" />
                </ListItem>
            </List>
            <Typography mt={1} variant="body2">
                CARUUPEに掲載されている
                <ExternalLink
                    icon
                    href="https://sites.google.com/cartahd.com/caruupe/%E3%83%9B%E3%83%BC%E3%83%A0/%E4%BA%BA%E4%BA%8B%E5%8A%B4%E5%8B%99/%E3%83%92%E3%83%88%E3%83%8E%E3%83%AF%E3%81%AE%E3%83%97%E3%83%AD%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E7%94%BB%E5%83%8F%E8%A8%AD%E5%AE%9A"
                    sx={{ mx: 0.5 }}
                >
                    ヒトノワのプロフィール画像設定
                </ExternalLink>
                も参照してください。
            </Typography>
        </Alert>
    );
};
