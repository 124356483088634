import { Box, Button } from '@mui/material';
import React from 'react';
import { NavigateBeforeIcon } from '../../Icons';

export const BackButton: React.FC = () => {
    return (
        <Box>
            <Button onClick={() => window.history.back()} startIcon={<NavigateBeforeIcon />}>
                前の画面に戻る
            </Button>
        </Box>
    );
};
