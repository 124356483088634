import type {
    ProfileImage,
    ScreenedImagesResponse,
    UnscreenedImagesResponse,
} from '@spec/profileImage';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useGateway } from '../stores/Gateway';
import { queryKey } from './queryKey';

export const useUnscreenedProfileImages = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.profileImages, 'unscreened'],
        queryFn: async () =>
            gateway
                .get<UnscreenedImagesResponse>('/profile-image-screenings/unscreened')
                .then((res) => res.images),
    });
};

export const useScreenedProfileImages = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.profileImages, 'screened'],
        queryFn: async () =>
            gateway
                .get<ScreenedImagesResponse>('/profile-image-screenings/screened')
                .then((res) => res.images),
    });
};

export const useUnscreenedTalentCount = () => {
    const unscreenedImagesQuery = useUnscreenedProfileImages();

    // データがロード中または利用できない場合は0を返す
    if (!unscreenedImagesQuery.data) {
        return 0;
    }

    // 画像をtalentIdでグループ化し、ユニークな人数を取得
    const groupedImages = Map.groupBy(unscreenedImagesQuery.data, (v) => v.talentId);
    return groupedImages.size;
};

export const useScreeningProfileImages = (images: ProfileImage[]) => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (approveImageId: number | null) => {
            // 採用する画像があれば承認
            if (approveImageId !== null) {
                const rejectImageIds = images
                    .filter((img) => img.id !== approveImageId)
                    .map((img) => img.id);
                // approveの時に却下する画像IDも一緒に送る
                await gateway.post(`/profile-image-screenings/${approveImageId}/approve`, {
                    rejectImageIds,
                });
            } else {
                // 画像をすべて却下
                await gateway.post(`/profile-image-screenings/reject`, {
                    imageIds: images.map((img) => img.id),
                });
            }
        },
        onSettled: () =>
            Promise.all([
                queryClient.invalidateQueries({ queryKey: [queryKey.profileImages] }),
                queryClient.invalidateQueries({ queryKey: [queryKey.talents] }),
            ]),
    });
};
