import { Box, List, ListItem, MenuItem, TextField, Typography } from '@mui/material';
import { useTalentsContext } from '../Context';
import { FilterCondition, FilterForm, FilterRow } from '../FilterForm';
import { FlexBox } from '../FlexBox';
import { HelpTip } from '../HelpTip';
import { MultiSelect } from '../MultiSelect';
import { TeamDrill } from '../TeamDrill';
import { useFilterFormContext } from './Context';
import { asScreeningStatus } from './domains';

const FIRST_LABEL_WIDTH = 3;

export const ScreeningFilterForm = () => {
    const { condition, updateCondition } = useFilterFormContext();
    const { availableTalents } = useTalentsContext();
    const employmentStatuses = new Set(availableTalents.map((v) => v.employment.employmentStatus));
    return (
        <FilterForm dense>
            <Typography my={1} variant="body2" color="primary">
                対象者を絞り込む
            </Typography>
            <FilterRow>
                <FilterCondition label="雇用形態" labelWidth={FIRST_LABEL_WIDTH}>
                    <MultiSelect
                        values={condition.employmentStatus}
                        setItems={(v) => updateCondition({ employmentStatus: v })}
                    >
                        {[...employmentStatuses].sort().map((v) => (
                            <MenuItem key={v} value={v}>
                                {v}
                            </MenuItem>
                        ))}
                    </MultiSelect>
                </FilterCondition>
                <FilterCondition
                    label={
                        <FlexBox>
                            社員区分
                            <HelpTip title="社員番号の先頭1〜2文字で検索できます" />
                        </FlexBox>
                    }
                >
                    <TextField
                        value={condition.employeeCode}
                        onChange={(e) => {
                            updateCondition({
                                employeeCode: e.target.value,
                            });
                        }}
                        sx={{ width: '3rem' }}
                        slotProps={{
                            htmlInput: { maxLength: 2 },
                        }}
                    />
                </FilterCondition>
                <FilterCondition
                    flexGrow={1}
                    label={
                        <FlexBox>
                            キーワード
                            <HelpTip
                                title={
                                    <Box m={1}>
                                        以下の項目にマッチします。
                                        <List dense disablePadding>
                                            <ListItem>ヒトノワID</ListItem>
                                            <ListItem>氏名（漢字、よみがな、英語表記）</ListItem>
                                            <ListItem>ニックネーム</ListItem>
                                            <ListItem>Slack名</ListItem>
                                            <ListItem>メールアドレス（エイリアス含む）</ListItem>
                                            <ListItem>GitHubID</ListItem>
                                            <ListItem>出向先の企業名</ListItem>
                                            <ListItem>役職</ListItem>
                                        </List>
                                    </Box>
                                }
                            />
                        </FlexBox>
                    }
                >
                    <Box flexGrow={1}>
                        <TextField
                            fullWidth
                            placeholder="対象者の名前やメールアドレス、出向先の企業名などで部分検索できます。"
                            value={condition.keyword}
                            onChange={(e) => updateCondition({ keyword: e.target.value })}
                        />
                    </Box>
                </FilterCondition>
            </FilterRow>
            <FilterRow>
                <FilterCondition label="所属" labelWidth={FIRST_LABEL_WIDTH}>
                    <TeamDrill
                        teamId={condition.teamId}
                        setTeamId={(teamId) => updateCondition({ teamId })}
                    />
                </FilterCondition>
            </FilterRow>
        </FilterForm>
    );
};

export const StatusFilterForm = () => {
    const { condition, updateCondition } = useFilterFormContext();
    return (
        <FilterForm dense>
            <Typography my={1} variant="body2" color="primary">
                審査結果で絞り込む
            </Typography>
            <FilterRow>
                <FilterCondition label="審査結果" labelWidth={FIRST_LABEL_WIDTH}>
                    <TextField
                        select
                        value={condition.status ?? 'all'}
                        onChange={(e) => {
                            const status = asScreeningStatus(e.target.value);
                            updateCondition({ status });
                        }}
                    >
                        <MenuItem value="all">指定なし</MenuItem>
                        <MenuItem value="approved">OK</MenuItem>
                        <MenuItem value="rejected">NG</MenuItem>
                        <MenuItem value="withdrawn">取り下げ</MenuItem>
                    </TextField>
                </FilterCondition>
            </FilterRow>
        </FilterForm>
    );
};
