import { Box } from '@mui/material';
import { Outlet, Route } from 'react-router-dom';
import { RoutingPattern } from '../../Routes';
import { PersonIcon } from '../Icons';
import { ModalBase } from '../PageModal';
import { PageTitle } from '../PageTitle';
import { TalentDetail } from './detail';
import { EditMe } from './editMe';
import { ProfileImage } from './editMe/ProfileImage';
import { TalentList } from './list';
import { Newcomers } from './newcomers';
import { Workplaces } from './workplaces';

export function TalentRoutes() {
    return (
        <Route path={RoutingPattern.talents} element={<Layout />}>
            <Route
                path=""
                element={<ModalBase path={RoutingPattern.talents} element={<TalentList />} />}
            >
                <Route path=":employeeCode/*" element={<TalentDetail />} />
            </Route>
            <Route path="me/edit">
                <Route index element={<EditMe />} />
                <Route path="profile-image" element={<ProfileImage />} />
            </Route>
            <Route path="workplaces" element={<Workplaces />} />
            <Route path="newcomers/:year/:month" element={<Newcomers />} />
        </Route>
    );
}

function Layout() {
    return (
        <Box>
            <PageTitle icon={PersonIcon} title="人を探す" />
            <Outlet />
        </Box>
    );
}
