import type { Team } from '@spec/Organization';
import type { ScreenedProfileImage } from '@spec/profileImage';
import type { Talent } from '@spec/Talent';
import { findChildTeams } from '../../domains/Organization';
import { searchTalent } from '../../domains/Talent';
import type { FilterCondition } from './Context';

export type ScreeningStatus = 'approved' | 'rejected' | 'withdrawn';

export const asScreeningStatus = (v: string): ScreeningStatus | null => {
    switch (v) {
        case 'approved':
        case 'rejected':
        case 'withdrawn':
            return v;
        default:
            return null;
    }
};

export const filterTalents = (
    talents: Talent[],
    teams: Team[],
    condition: FilterCondition
): Talent[] => {
    const teamIds =
        condition.teamId === null ? [] : findChildTeams(condition.teamId, teams).map((v) => v.id);
    return talents
        .filter((v) => (condition.teamId === null ? true : teamIds.includes(v.teamId)))
        .filter((v) => (condition.keyword === '' ? true : searchTalent(condition.keyword, v)))
        .filter((v) => {
            if (condition.employmentStatus.length === 0) {
                return true;
            }
            return condition.employmentStatus.includes(v.employment.employmentStatus);
        })
        .filter((v) => {
            if (condition.employeeCode === '') {
                return true;
            }
            return v.employment.employeeCode
                .toLowerCase()
                .startsWith(condition.employeeCode.toLowerCase());
        });
};

export const filterScreenedImage = (
    image: ScreenedProfileImage,
    condition: FilterCondition
): boolean => {
    if (condition.status === null) {
        return true;
    }
    return detectScreeningStatus(image) === condition.status;
};

export const detectScreeningStatus = (v: ScreenedProfileImage): ScreeningStatus => {
    if (v.isApproved === true) {
        return 'approved';
    }
    const selfUploaded = v.talentId === v.uploadTalentId;
    const selfRejected = v.talentId === v.reviewerTalentId;
    if (selfUploaded && selfRejected) {
        return 'withdrawn';
    }
    return 'rejected';
};
