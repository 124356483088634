import { Route } from 'react-router-dom';
import { ProfileImageScreening } from '.';
import { RoutingPattern } from '../../Routes';
import { Layout } from './Layout';
import { ScreeningHistory } from './ScreeningHistory';

export const ProfileImageScreeningRoutes = () => (
    <Route path={RoutingPattern.profileImageScreening} element={<Layout />}>
        <Route index element={<ProfileImageScreening />} />
        <Route path="history" element={<ScreeningHistory />} />
    </Route>
);
