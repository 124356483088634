import {
    Alert,
    Box,
    Button,
    ButtonProps,
    Card,
    CardContent,
    Chip,
    Divider,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { findById } from '../../../../lib/ArrayUtils';
import { useEmptyForm } from '../../../../lib/Form';
import { usePreviewTodoItem } from '../../../../queries/todoAdmin';
import { FlexBox } from '../../../FlexBox';
import { DeleteIcon, EditIcon, MarkUnreadChatAltIcon } from '../../../Icons';
import { SlackMrkDwn } from '../../../Markdown';
import { SubmitButton } from '../../../SubmitButton';
import { useTodoItemsContext } from '../Context';
import { useDialogContext, useTodoItemContext } from './Context';

export const TodoItemCard: React.FC = () => {
    const theme = useTheme();
    const { categories } = useTodoItemsContext();
    const { item, assignments } = useTodoItemContext();
    const category = findById(item.todoCategoryId, categories);
    const { setDialogMode } = useDialogContext();
    const previewForm = useEmptyForm();
    const mutation = usePreviewTodoItem();
    return (
        <Card>
            <CardContent>
                <FlexBox gap={1}>
                    <Chip color="primary" label={category.label} />
                    <Typography>{item.title}</Typography>
                </FlexBox>
                <Box my={0.5}>
                    <Divider />
                </Box>
                <Box display="flex" m={1} gap={2}>
                    <Box flexGrow={1}>
                        <Typography variant="body2" color="primary">
                            締切日時
                        </Typography>
                        <Typography variant="body2">
                            {item.deadline === null
                                ? '締切なし'
                                : dayjs(item.deadline).format('YYYY-MM-DD (ddd) HH:mm')}
                        </Typography>
                    </Box>
                    <Box flexGrow={1}>
                        <Typography variant="body2" color="primary">
                            リマインド日時
                        </Typography>
                        {item.reminders.length === 0 ? (
                            <Typography variant="body2">設定なし</Typography>
                        ) : (
                            item.reminders.map((v) => (
                                <Typography key={v.getTime()} variant="body2">
                                    {dayjs(v).format('YYYY-MM-DD (ddd) HH:mm')}
                                </Typography>
                            ))
                        )}
                    </Box>
                </Box>
                <Box sx={{ padding: 1, backgroundColor: theme.palette.background.default }}>
                    <SlackMrkDwn source={item.description} />
                </Box>
                {previewForm.failed && (
                    <Box mt={2}>
                        <Alert severity="error">テスト通知に失敗しました</Alert>
                    </Box>
                )}
                {previewForm.succeeded && (
                    <Box mt={2}>
                        <Alert severity="success">あなたにテスト通知を送りました</Alert>
                    </Box>
                )}
                <FlexBox mt={2} textAlign="center" justifyContent="center" gap={2}>
                    <Button
                        size="medium"
                        variant="outlined"
                        startIcon={<EditIcon />}
                        onClick={() => setDialogMode('EditTodoItem')}
                    >
                        編集する
                    </Button>
                    <SubmitButton
                        size="medium"
                        variant="outlined"
                        inProgress={previewForm.inProgress}
                        startIcon={<MarkUnreadChatAltIcon />}
                        onClick={() =>
                            previewForm.send(
                                mutation.mutateAsync({
                                    todoCategoryId: item.todoCategoryId,
                                    title: item.title,
                                    description: item.description,
                                    deadline: item.deadline,
                                    reminders: item.reminders,
                                })
                            )
                        }
                    >
                        自分にテスト通知する
                    </SubmitButton>
                    <ActionButton
                        tooltipTitle="対象者がいるので削除できません"
                        disabled={assignments.length > 0}
                        startIcon={<DeleteIcon />}
                        onClick={() => setDialogMode('DeleteTodoItem')}
                    >
                        削除する
                    </ActionButton>
                </FlexBox>
            </CardContent>
        </Card>
    );
};

const ActionButton: React.FC<ButtonProps & { tooltipTitle?: string }> = (props) => {
    const { tooltipTitle, ...rest } = props;
    return (
        <Tooltip
            title={tooltipTitle ?? ''}
            placement="top"
            followCursor
            disableHoverListener={!props.disabled}
        >
            <div>
                <Button size="medium" variant="outlined" {...rest} />
            </div>
        </Tooltip>
    );
};
