import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { sortByKey } from '../../../../lib/ArrayUtils';
import { useMyTouches } from '../../../../queries/me';
import { Pager, sliceItems } from '../../../Pager';
import { WaitQuery } from '../../../WaitLoading';

export const TouchHistory = () => {
    const [page, setPage] = useState(1);
    const ITEMS_PER_PAGE = 20;
    const maybeTouches = useMyTouches();

    return (
        <Box>
            <WaitQuery query={maybeTouches}>
                {({ data }) => {
                    const touches = sortByKey(data, 'matchedAt', 'desc');
                    const slicedTouches = sliceItems(touches, page, ITEMS_PER_PAGE);

                    return (
                        <Box>
                            <Typography>これまでに{touches.length}人とタッチしました</Typography>
                            <Pager
                                current={page}
                                setPage={setPage}
                                amount={touches.length}
                                perItems={ITEMS_PER_PAGE}
                            />
                            <Table sx={{ width: 'auto', mt: 1 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>所属</TableCell>
                                        <TableCell>氏名</TableCell>
                                        <TableCell>最新タッチ</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {slicedTouches.map((v) => {
                                        return (
                                            <TableRow key={v.matchedAt.toISOString()}>
                                                <TableCell>{v.profile.team}</TableCell>
                                                <TableCell>{v.profile.name}</TableCell>
                                                <TableCell>
                                                    {dayjs(v.matchedAt).format(
                                                        'YYYY/MM/DD HH:mm:ss'
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                            <Pager
                                current={page}
                                setPage={setPage}
                                amount={touches.length}
                                perItems={ITEMS_PER_PAGE}
                            />
                        </Box>
                    );
                }}
            </WaitQuery>
        </Box>
    );
};
