import { Box, Button, Card, CardContent, Divider, Typography, useTheme } from '@mui/material';
import { Survey, SurveyQuestion } from '@spec/Survey';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { RoutingPattern } from '../../../../Routes';
import { ActionButton, ActionContainer } from '../../../ActionButtons';
import { FlexBox } from '../../../FlexBox';
import { DeleteIcon, EditIcon, HelpCenterIcon } from '../../../Icons';
import { Markdown } from '../../../Markdown';
import { SubTitle } from '../../../PageTitle';
import { RequirePrivilege } from '../../../RequirePrivilege';
import { ContextualDialog, DialogComponents } from '../../../StableDialog';
import { useSurveysContext } from '../../Context';
import { ContextProvider, DialogMode, useDialogContext } from './Context';
import { AddQuestionDialog } from './dialogs/AddQuestionDialog';
import { DeleteQuestionDialog } from './dialogs/DeleteQuestionDialog';
import { DeleteSurveyDialog } from './dialogs/DeleteSurveyDialog';
import { EditQuestionDialog } from './dialogs/EditQuestionDialog';
import { EditSurveyDialog } from './dialogs/EditSurveyDialog';
import { QuestionList } from './QuestionList';

export const Admin: React.FC = () => {
    const surveyId = Number(useParams().surveyId);
    const { surveys } = useSurveysContext();
    const survey = surveys.find((v) => v.id === surveyId);
    if (survey === undefined) {
        return <Navigate to={RoutingPattern.surveyAdmin} />;
    }
    return (
        <ContextProvider survey={survey}>
            <RequirePrivilege fn={(grants) => grants.manageSurvey}>
                <SubTitle title="サーベイの管理" />
                <SurveyDetail survey={survey} />
                <Questions questions={survey.questions} />
                <ContextualDialog components={dialogComponents} />
            </RequirePrivilege>
        </ContextProvider>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    EditSurvey: EditSurveyDialog,
    DeleteSurvey: DeleteSurveyDialog,
    AddQuestion: AddQuestionDialog,
    EditQuestion: EditQuestionDialog,
    DeleteQuestion: DeleteQuestionDialog,
};

const SurveyDetail: React.FC<{ survey: Survey }> = (props) => {
    const theme = useTheme();
    const { setDialogMode } = useDialogContext();
    return (
        <Box mt={2}>
            <Typography>サーベイの説明</Typography>
            <Card>
                <CardContent>
                    <FlexBox gap={1}>
                        <Typography>{props.survey.name}</Typography>
                    </FlexBox>
                    <Box my={0.5}>
                        <Divider />
                    </Box>
                    <Box
                        mt={1}
                        sx={{ padding: 1, backgroundColor: theme.palette.background.default }}
                    >
                        <Markdown source={props.survey.description} variant="body2" />
                    </Box>
                    <FlexBox mt={2} textAlign="center" justifyContent="center" gap={2}>
                        <Button
                            size="medium"
                            variant="outlined"
                            startIcon={<EditIcon />}
                            onClick={() => setDialogMode('EditSurvey')}
                        >
                            編集する
                        </Button>
                        <Button
                            size="medium"
                            variant="outlined"
                            startIcon={<DeleteIcon />}
                            onClick={() => setDialogMode('DeleteSurvey')}
                        >
                            削除する
                        </Button>
                    </FlexBox>
                </CardContent>
            </Card>
        </Box>
    );
};

const Questions: React.FC<{ questions: SurveyQuestion[] }> = (props) => {
    const { setDialogMode } = useDialogContext();
    return (
        <Box mt={4}>
            <Typography>登録済みの質問</Typography>
            <QuestionList questions={props.questions} />
            <ActionContainer mt={2}>
                <ActionButton
                    startIcon={<HelpCenterIcon />}
                    onClick={() => setDialogMode('AddQuestion')}
                >
                    新しい質問を作る
                </ActionButton>
            </ActionContainer>
        </Box>
    );
};
