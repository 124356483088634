import { HowToRegIcon } from '../../../../components/Icons';
import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { Talent, WithdrawTalentRequest } from '@spec/Talent';
import React, { useState } from 'react';
import { Form, Strict, useForm, useFormDate } from '../../../../lib/Form';
import { required } from '../../../../lib/Form/Validators';
import { useWithdrawTalent } from '../../../../queries/talent';
import { ActionButton } from '../../../ActionButtons';
import { useCurrentTimeContext } from '../../../Context';
import { DateInput } from '../../../DateInput';
import { DialogActionButtons, DialogRow, StableDialog } from '../../../StableDialog';

interface Elements {
    leavedAt: Date | null;
}

const guard = (v: Elements): v is Strict<Elements> => v.leavedAt !== null;

const toWithdrawTalentRequest = (form: Form<Elements>): WithdrawTalentRequest => {
    const args = form.serialize();
    if (!guard(args)) {
        throw Error('Invalid form values');
    }
    return args;
};

export const WithdrawTalent: React.FC<{ talent: Talent }> = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const form = useForm<Elements>({
        leavedAt: useFormDate(null, [required]),
    });
    const { currentTime } = useCurrentTimeContext();
    const mutation = useWithdrawTalent(props.talent.employment.employeeCode);
    return (
        <>
            <StableDialog noClose open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>従業員の退職</DialogTitle>
                <DialogContent>
                    <Typography>
                        退職させると現在の有効な異動履歴は退職日ですべて離任扱いになります。
                    </Typography>
                    <Typography>
                        安全のため、このアカウントが持っているすべての権限は剥奪されます。
                    </Typography>
                    <DialogRow label="退職日（未来の日付は設定できません）">
                        <DateInput autoFocus formDate={form.leavedAt} max={currentTime} />
                    </DialogRow>
                </DialogContent>
                <DialogActionButtons
                    form={form}
                    startIcon={<HowToRegIcon />}
                    submitLabel="退職させる"
                    cancelLabel="キャンセル"
                    errorMessage="退職処理に失敗しました"
                    onSubmit={() => mutation.mutateAsync(toWithdrawTalentRequest(form))}
                    closeDialog={() => setDialogOpen(false)}
                />
            </StableDialog>
            <ActionButton startIcon={<HowToRegIcon />} onClick={() => setDialogOpen(true)}>
                この従業員を退職させる
            </ActionButton>
        </>
    );
};
