import { Box, BoxProps, Paper, SxProps, Typography } from '@mui/material';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormTheme } from '../Theme';
import { FlexBox } from './FlexBox';

type ContextFactoryOptions<T> = {
    filterKeys: Record<keyof T, string>;
    initialize: (params: URLSearchParams) => T;
    serialize: (condition: T) => Record<string, string | undefined>;
};

const PAGE_KEY = 'p';

export const createFilterContext = <T extends Record<string, any>>(
    options: ContextFactoryOptions<T>
) => {
    const FilterContext = createContext(
        {} as {
            condition: T;
            updateCondition: (v: Partial<T>) => void;
            page: number;
            setPage: (v: number) => void;
        }
    );

    const FilterContextProvider = (props: { children: ReactNode }) => {
        const navigate = useNavigate();
        const location = useLocation();

        const [condition, setCondition] = useState<T>(() => {
            const urlParams = new URLSearchParams(location.search);
            return options.initialize(urlParams);
        });

        const updateCondition = (v: Partial<T>) => {
            setCondition((before) => {
                return { ...before, ...v };
            });
            setPage(1); // 条件変更時はページを1に戻す
        };

        const [page, setPage] = useState(() => {
            const params = new URLSearchParams(location.search);
            return Number(params.get(PAGE_KEY) ?? 1);
        });

        useEffect(() => {
            const params = new URLSearchParams();
            for (const [key, value] of Object.entries(options.serialize(condition))) {
                if (value !== undefined) {
                    params.set(options.filterKeys[key], value);
                }
            }
            if (page > 1) {
                params.set(PAGE_KEY, `${page}`);
            }
            const nextSearch = params.toString() ? `?${params}` : '';
            navigate(`${location.pathname}${nextSearch}`, { replace: true });
        }, [condition, navigate, page, location.pathname]);

        return (
            <FilterContext.Provider value={{ condition, updateCondition, page, setPage }}>
                {props.children}
            </FilterContext.Provider>
        );
    };

    const useFilterContext = () => useContext(FilterContext);

    const Provider = (props: { children: ReactNode }) => {
        return <FilterContextProvider>{props.children}</FilterContextProvider>;
    };

    return {
        useFilterContext,
        Provider,
    };
};

const FilterFormContext = createContext({} as { dense: boolean });
const useFilterFormContext = () => useContext(FilterFormContext);

export const FilterForm: React.FC<{ dense?: boolean; children: ReactNode }> = (props) => {
    const dense = props.dense ?? false;
    return (
        <FilterFormContext.Provider value={{ dense }}>
            <FormTheme>
                <Paper>
                    <Box px={dense ? 2 : 3} py={dense ? 0.5 : 1.5}>
                        {props.children}
                    </Box>
                </Paper>
            </FormTheme>
        </FilterFormContext.Provider>
    );
};

export const FilterRow: React.FC<{ children: ReactNode }> = (props) => {
    const { dense } = useFilterFormContext();
    return (
        <FlexBox py={dense ? 0.5 : 1} gap={2}>
            {props.children}
        </FlexBox>
    );
};

export const FilterCondition: React.FC<
    BoxProps & { label?: ReactNode; labelWidth?: number; children: ReactNode }
> = (props) => {
    const { label, labelWidth, children, ...rest } = props;
    const sx: SxProps = { whiteSpace: 'nowrap' };
    if (labelWidth !== undefined) {
        sx.width = `${labelWidth}rem`;
    }
    return (
        <FlexBox flexWrap="nowrap" gap={1} {...rest}>
            {label && (
                <Typography variant="body2" sx={sx}>
                    {label}
                </Typography>
            )}
            {children}
        </FlexBox>
    );
};
