import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Box,
    Button,
    Typography,
} from '@mui/material';
import { Team } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import React, { memo, useCallback, useState } from 'react';
import { reportInfo } from '../../../Errors';
import { generateTalentsTsv } from '../../../domains/Talent';
import { useMeContext } from '../../../queries/me';
import { useTeamsContext } from '../../Context';
import { FlexBox } from '../../FlexBox';
import { CloudDownloadIcon, ContentCopyIcon, ExpandMoreIcon } from '../../Icons';

export const CsvDownload: React.FC<{ talents: Talent[] }> = (props) => {
    const { teams } = useTeamsContext();
    const { grants } = useMeContext();
    if (grants.downloadCsv !== true) {
        return null;
    }
    return (
        <Box mt={2}>
            <Accordion TransitionProps={{ mountOnEnter: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FlexBox>
                        <CloudDownloadIcon color="primary" />
                        <Box ml={1}>
                            <Typography variant="body2">HR用CSVダウンロード</Typography>
                        </Box>
                    </FlexBox>
                </AccordionSummary>
                <AccordionDetails>
                    <DownloadButton talents={props.talents} teams={teams} />
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

const DownloadButton: React.FC<{ talents: Talent[]; teams: Team[] }> = memo((props) => {
    const { me } = useMeContext();
    const [copied, setCopied] = useState(false);
    const copyTalents = useCallback(() => {
        reportInfo(`${me?.hitonowaId} downloads talents CSV`);
        const now = new Date();
        return navigator.clipboard
            .writeText(generateTalentsTsv(props.talents, props.teams, now))
            .then(() => setCopied(true))
            .then(() =>
                setTimeout(() => {
                    setCopied(false);
                }, 800)
            );
    }, [me, props.talents, props.teams]);
    return (
        <Box textAlign="center">
            <Alert severity="info">
                CSVダウンロードからクリップボードへのコピー機能に生まれ変わりました。そのままスプレッドシートにペーストできます。
            </Alert>
            <Alert severity="warning" sx={{ mt: 2 }}>
                この操作は「CSVダウンロード」の権限を持つ人にだけ許可されています。情報の取り扱いには十分注意してください。
            </Alert>
            <Box mt={2}>
                <Button
                    variant="outlined"
                    color="primary"
                    size="medium"
                    startIcon={<ContentCopyIcon />}
                    onClick={copyTalents}
                >
                    {copied
                        ? '従業員の情報をコピーしました'
                        : '検索条件に合致する従業員の情報をコピーする'}
                </Button>
            </Box>
        </Box>
    );
});
