import { env } from '@env';
import { Alert, Box, Typography, TypographyProps, useTheme } from '@mui/material';
import { FileCopyIcon } from '../Icons';
import React from 'react';
import { reportError } from '../../Errors';
import { useAsyncState } from '../../lib/AsyncResource';
import { useFirebaseUserContext } from '../Context';
import { FlexBox } from '../FlexBox';
import { HelpAccordion } from '../HelpAccordion';
import { SubmitButton } from '../SubmitButton';

export const HowTo: React.FC = () => {
    const { firebaseUser } = useFirebaseUserContext();
    const [succeeded, setSucceeded] = useAsyncState<true>({ inProgress: false, value: null });
    const failed = succeeded.value instanceof Error;
    return (
        <HelpAccordion title="APIの使い方">
            <Box mt={1} mx={1}>
                <Typography variant="h6" color="primary">
                    1.Tokenを取得する
                </Typography>
                <Paragraph>
                    以下のボタンをクリックするとクリップボードにTokenをコピーできます。
                </Paragraph>
                <FlexBox my={1} mx={1}>
                    <SubmitButton
                        startIcon={<FileCopyIcon />}
                        variant="outlined"
                        inProgress={succeeded.inProgress}
                        disabled={succeeded.inProgress}
                        onClick={() => {
                            setSucceeded({ inProgress: true, value: null });
                            firebaseUser
                                .getIdToken()
                                .then((token) => navigator.clipboard.writeText(token))
                                .then(() => {
                                    setSucceeded({ inProgress: false, value: true });
                                })
                                .catch((e) => {
                                    const err = e instanceof Error ? e : Error(e);
                                    reportError(err);
                                    setSucceeded({
                                        inProgress: false,
                                        value: err,
                                    });
                                });
                        }}
                    >
                        Tokenをクリップボードに取得
                    </SubmitButton>
                    {succeeded.value === true && (
                        <Box ml={2}>
                            <Typography variant="body2">コピーしました</Typography>
                        </Box>
                    )}
                </FlexBox>
                {failed && (
                    <Box my={2}>
                        <Alert severity="error">Tokenのコピーに失敗しました</Alert>
                    </Box>
                )}
                <Paragraph>
                    コピーしたTokenは<Code>export TOKEN=`pbpaste`</Code>
                    のように環境変数に入れておくと、CLIからAPIを操作するのに便利です。
                </Paragraph>
                <Paragraph>
                    動かないブラウザを利用している場合は、devtoolsなどでAPI通信に使われている情報を自分で見てTokenを取得してください。
                </Paragraph>
            </Box>
            <Box mt={3} mx={1}>
                <Typography variant="h6" color="primary">
                    2.APIを呼び出す
                </Typography>
                <Paragraph>
                    <Code>{env.apiHost}</Code>
                    に対してリファレンスを参照に任意のAPIリクエストを生成してください。
                </Paragraph>
                <Paragraph>
                    その際、1で取得したTokenをAuthorizationヘッダに
                    <Code>{'Bearer ${TOKEN}'}</Code>
                    として指定する必要があります。
                </Paragraph>
            </Box>
            <Box mt={3} mx={1}>
                <Typography variant="h6" color="primary">
                    Q: UIの通信をcopy as cURLとかしたらResponseが変なんですけど！
                </Typography>
                <Paragraph>
                    本システムでは取り扱う情報の性格上、ブラウザのdevtoolsからカジュアルにAPIレスポンスを取得することを良しとしていません。
                </Paragraph>
                <Paragraph>
                    そのため、ご利用のUIではAPIのレスポンスを暗号化して取得するように振る舞います。
                </Paragraph>
                <Paragraph>
                    リクエストのHeaderにそのモードを有効にするパラメータが存在するので、それを除去することで当該動作を無効化できます。
                </Paragraph>
                <Paragraph>
                    そうした情報を取り扱っていることを噛み締めた上で本APIをご利用ください。
                </Paragraph>
            </Box>
        </HelpAccordion>
    );
};

const Code: React.FC<TypographyProps> = (props) => {
    const theme = useTheme();
    return (
        <Typography
            display="inline"
            sx={{
                px: 0.5,
                whiteSpace: 'pre',
                fontFamily: '"Ubuntu Mono", monospace',
                fontWeight: 700,
                color: theme.palette.secondary.dark,
            }}
            {...props}
        />
    );
};

const Paragraph: React.FC<TypographyProps> = (props) => <Typography mt={0.5} mx={1} {...props} />;
