import { Box, Slide, Toolbar } from '@mui/material';
import React, { useState } from 'react';
import {
    Outlet,
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
    useLocation,
    useRouteError,
} from 'react-router-dom';
import { RoutingPattern } from '../../Routes';
import { useMobile } from '../../Theme';
import { useTeams } from '../../queries/organization';
import {
    CurrentTimeContextProvider,
    TalentsContextProvider,
    TeamsContextProvider,
    useTenantContext,
} from '../Context';
import { WaitQuery } from '../WaitLoading';
import { AdminRoutes } from '../admin/Routes';
import { AnnouncementDialog } from '../announcement/AnnouncementDialog';
import { ApiReferenceRoutes } from '../apiReference/Routes';
import { Dashboard } from '../dashboard';
import { Grants } from '../grants';
import { NotFound } from '../notFound';
import { NotesRoutes } from '../notes/Routes';
import { Logs } from '../operationLogs';
import { OrganizationRoutes } from '../organization/Routes';
import { ProfileImageScreeningRoutes } from '../profileImageScreening/Routes';
import { RegisterTalent } from '../register';
import { SurveyRoutes } from '../survey/Routes';
import { TalentRoutes } from '../talent';
import { Iam, Me } from '../talent/iam';
import { TodoRoutes } from '../todo/Routes';
import { Touch } from '../touch';
import { Analytics } from './Analytics';
import { DevelopmentAlert } from './DevelopAlert';
import { Footer } from './Footer';
import { Header } from './Header';
import { ScrollToTop } from './ScrollToTop';
import { Nav } from './nav/Nav';

// between drawer (sidebar) and modal
// https://mui.com/material-ui/customization/z-index/
const FOOTER_Z_INDEX = 1250;

export const Layout = (props: { basePath: string }) => {
    const { tenant } = useTenantContext();
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<BaseLayout />} errorElement={<ErrorBoundary />}>
                <Route index element={<Dashboard />} />
                <Route path={RoutingPattern.me} element={<Me />} />
                <Route path={RoutingPattern.iam} element={<Iam />} />
                <Route path={RoutingPattern.registerTalent} element={<RegisterTalent />} />
                {TodoRoutes()}
                {TalentRoutes()}
                {OrganizationRoutes()}
                {SurveyRoutes()}
                <Route path={RoutingPattern.grants} element={<Grants />} />
                {ProfileImageScreeningRoutes()}
                <Route path={RoutingPattern.touch} element={<Touch />} />
                <Route path={RoutingPattern.logs} element={<Logs />} />
                {ApiReferenceRoutes()}
                <Route path="*" element={<NotFound />} />
                {NotesRoutes()}
                {tenant === 'carta' && (
                    <>
                        {AdminRoutes()}
                        <Route
                            path={`${RoutingPattern.valueFeedbacks}/*`}
                            lazy={() => import('../valueFeedback/Routes')}
                        />
                        <Route
                            path={RoutingPattern.techAssessment}
                            lazy={() => import('../techAssessment')}
                        />
                        <Route
                            path={`${RoutingPattern.circle}/*`}
                            lazy={() => import('../circle/Routes')}
                        />
                        <Route
                            path={`${RoutingPattern.job}/*`}
                            lazy={() => import('../job/Routes')}
                        />
                    </>
                )}
            </Route>
        ),
        { basename: `/${props.basePath}` }
    );
    return <RouterProvider router={router} />;
};

const BaseLayout: React.FC = () => {
    const [currentTime, setCurrentTime] = useState(new Date());
    const location = useLocation();
    const [pathname, setPathname] = useState(location.pathname);
    if (pathname !== location.pathname) {
        setPathname(location.pathname);
        setCurrentTime(new Date());
    }
    const maybeTeams = useTeams();
    return useMobile() ? (
        <CurrentTimeContextProvider currentTime={currentTime}>
            <ScrollToTop />
            <Analytics />
            <WaitQuery query={maybeTeams}>
                {({ data }) => (
                    <TeamsContextProvider teams={data}>
                        <TalentsContextProvider>
                            <AnnouncementDialog key={location.pathname} />
                            <Box sx={{ width: '100%', maxWidth: '100vw', overflowX: 'hidden' }}>
                                <Header />
                                <DevelopmentAlert />
                                <Box px={2} py={3}>
                                    <Outlet />
                                </Box>
                            </Box>
                        </TalentsContextProvider>
                    </TeamsContextProvider>
                )}
            </WaitQuery>
            <Footer />
        </CurrentTimeContextProvider>
    ) : (
        <CurrentTimeContextProvider currentTime={currentTime}>
            <ScrollToTop />
            <Analytics />
            <Box display="flex" flexDirection="column" sx={{ minHeight: '100vh' }}>
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                    }}
                >
                    <Toolbar variant="dense" />
                </Box>
                <Box flexGrow={1} display="flex" minWidth={0}>
                    <Slide direction="right" in={true}>
                        <Box>
                            <Nav />
                        </Box>
                    </Slide>
                    <Box flexGrow={1} minWidth={0}>
                        <WaitQuery query={maybeTeams}>
                            {({ data }) => (
                                <TeamsContextProvider teams={data}>
                                    <TalentsContextProvider>
                                        <AnnouncementDialog key={location.pathname} />
                                        <Header />
                                        <DevelopmentAlert />
                                        <Box px={2} py={3}>
                                            <Outlet />
                                        </Box>
                                    </TalentsContextProvider>
                                </TeamsContextProvider>
                            )}
                        </WaitQuery>
                    </Box>
                </Box>
                <Box zIndex={FOOTER_Z_INDEX}>
                    <Footer />
                </Box>
            </Box>
        </CurrentTimeContextProvider>
    );
};

function ErrorBoundary() {
    const error = useRouteError();
    if (error) {
        throw error instanceof Error ? error : Error('Unknown error');
    }
    return null;
}
