import {
    AppBar,
    Badge,
    Box,
    Card,
    CardContent,
    ClickAwayListener,
    Divider,
    Drawer,
    IconButton,
    Switch,
    Toolbar,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { Brightness5Icon, NightsStayIcon } from '../Icons';
import { NotesNotification } from '@spec/Notes';
import { Team } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApplicationError } from '../../Errors';
import { getIamUrl, getTalentUrl, getTeamUrl, RoutingPattern } from '../../Routes';
import { useMobile } from '../../Theme';
import { fullName } from '../../domains/Talent';
import { findById } from '../../lib/ArrayUtils';
import { queryToArray } from '../../queries';
import { useMeContext } from '../../queries/me';
import {
    useAuthorsNote,
    useMarkNotesNotificationAsRead,
    useNotesNotifications,
} from '../../queries/notes';
import { useTalents } from '../../queries/talent';
import { CardLink } from '../ActionButtons';
import { useColorModeContext, useCurrentTimeContext, useTalentsContext } from '../Context';
import { FlexBox } from '../FlexBox';
import { FiberNewIcon, MenuIcon, NotificationsIcon } from '../Icons';
import { Logo } from '../Logo';
import { RouterLink } from '../RouterLink';
import { TalentAvatar } from '../TalentAvatar';
import { TenantContent } from '../TenantContent';
import { WaitQuery } from '../WaitLoading';
import { getArticleUrl } from '../notes/urls';
import { isTalent, isTeam } from '../search/Domains';
import { Search } from '../search/Search';
import { ThemeSelector } from './ThemeSelector';
import { Carta } from './nav/Carta';
import { Treasure2024 } from './nav/Treasure2024';

export const Header: React.FC = () => {
    const theme = useTheme();
    const { colorMode, toggleColorMode } = useColorModeContext();
    const maybeTalents = useTalents();
    const [currentItem, setCurrentItem] = useState<Talent | Team | null>(null);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (isTalent(currentItem)) {
            navigate(getTalentUrl(currentItem.employment.employeeCode));
        }
        if (isTeam(currentItem)) {
            navigate(getTeamUrl(currentItem.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentItem]);
    const [openNav, setOpenNav] = useState(false);
    const isMobile = useMobile();
    return (
        <>
            <AppBar
                sx={{ backgroundColor: theme.palette.nav.background }}
                position="static"
                elevation={0}
            >
                <Toolbar variant="dense" disableGutters>
                    {isMobile === true && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ ml: 0.5, mr: 2 }}
                            onClick={() => setOpenNav((prev) => !prev)}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    <Box flexGrow={1} />
                    <Box sx={{ width: '100%', maxWidth: '23rem' }}>
                        <Search
                            maybeTalents={maybeTalents}
                            value={currentItem}
                            onChange={(v) => setCurrentItem(v)}
                        />
                    </Box>
                    <Box ml={2} pt={0.5}>
                        <Notifications key={location.pathname} />
                    </Box>
                    {isMobile === false && (
                        <Box ml={2.5}>
                            <You />
                        </Box>
                    )}
                    <Box ml={2}>
                        <FlexBox
                            flexWrap="nowrap"
                            gap={theme.spacing(1, 0)}
                            color={theme.palette.nav.icon}
                        >
                            <Brightness5Icon fontSize="small" sx={{ opacity: 0.8 }} />
                            <Switch
                                size="small"
                                checked={colorMode === 'dark'}
                                onChange={toggleColorMode}
                            />
                            <NightsStayIcon fontSize="small" sx={{ opacity: 0.8 }} />
                        </FlexBox>
                    </Box>
                    <Box ml={1} mr={1}>
                        <ThemeSelector />
                    </Box>
                </Toolbar>
            </AppBar>
            {isMobile === true && (
                <Drawer
                    open={openNav}
                    onClose={() => setOpenNav(false)}
                    PaperProps={{
                        sx: {
                            backgroundColor: theme.palette.nav.background,
                            color: theme.palette.nav.text,
                        },
                    }}
                >
                    <Box onClick={() => setOpenNav(false)}>
                        <Toolbar variant="dense">
                            <Box my={2}>
                                <You />
                            </Box>
                        </Toolbar>
                        <Divider />
                        <Box>
                            <TenantContent carta={<Carta />} treasure2024={<Treasure2024 />} />
                        </Box>
                    </Box>
                </Drawer>
            )}
        </>
    );
};

export const MobileHeader = () => {
    const theme = useTheme();
    const { colorMode, toggleColorMode } = useColorModeContext();
    const location = useLocation();
    const [openNav, setOpenNav] = useState(false);
    return (
        <>
            <AppBar
                sx={(theme) => ({ backgroundColor: theme.palette.nav.background })}
                position="static"
                elevation={0}
            >
                <Toolbar variant="dense" disableGutters>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ ml: 0.5, mr: 2 }}
                        onClick={() => setOpenNav((prev) => !prev)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box flexGrow={1} />
                    <Box pt={0.5}>
                        <Notifications key={location.pathname} />
                    </Box>
                    <Box ml={2}>
                        <FlexBox
                            flexWrap="nowrap"
                            gap={theme.spacing(1, 0)}
                            color={theme.palette.nav.icon}
                        >
                            <Brightness5Icon fontSize="small" sx={{ opacity: 0.8 }} />
                            <Switch
                                size="small"
                                checked={colorMode === 'dark'}
                                onChange={toggleColorMode}
                            />
                            <NightsStayIcon fontSize="small" sx={{ opacity: 0.8 }} />
                        </FlexBox>
                    </Box>
                    <Box ml={1} mr={1}>
                        <ThemeSelector />
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                open={openNav}
                onClose={() => setOpenNav(false)}
                PaperProps={{
                    sx: {
                        backgroundColor: theme.palette.nav.background,
                        color: theme.palette.nav.text,
                    },
                }}
            >
                <Box onClick={() => setOpenNav(false)}>
                    <Toolbar
                        variant="dense"
                        sx={{
                            background: theme.palette.background.default,
                        }}
                    >
                        <Box mt={0.5} mb={-0.5} ml={5.5}>
                            <RouterLink to={RoutingPattern.index}>
                                <Logo size={90} />
                            </RouterLink>
                        </Box>
                    </Toolbar>
                    <Box>
                        <TenantContent carta={<Carta />} treasure2024={<Treasure2024 />} />
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};

const Notifications: React.FC = () => {
    const theme = useTheme();
    const { me } = useMeContext();
    const maybeArticles = useAuthorsNote(me.hitonowaId);
    const maybeNotifications = useNotesNotifications();
    const notifications = queryToArray(maybeNotifications);

    const [open, setOpen] = useState<string | null>(null);

    if (maybeArticles.isPending || maybeNotifications.isPending) {
        return (
            <NotificationsIcon
                sx={{
                    mt: '7px',
                    color: theme.palette.nav.icon,
                    opacity: 0.5,
                }}
            />
        );
    }
    if (notifications.length === 0) {
        return (
            <Tooltip arrow title="この一週間の通知はありません">
                <NotificationsIcon
                    sx={{
                        mt: '7px',
                        color: theme.palette.nav.icon,
                        opacity: 0.5,
                    }}
                />
            </Tooltip>
        );
    }

    const newNotifications = notifications.filter((v) => v.unread === true);
    return (
        <ClickAwayListener
            onClickAway={() => {
                setOpen(null);
            }}
        >
            <div>
                <Tooltip
                    disableTouchListener
                    open={!!open}
                    onOpen={() => {
                        setOpen('hover');
                    }}
                    leaveDelay={500}
                    onClose={() => {
                        if (open === 'hover') {
                            setOpen(null);
                        }
                    }}
                    components={{ Tooltip: Box }}
                    title={<NotificationCard notifications={notifications} />}
                >
                    <IconButton
                        onClick={() => setOpen((prev) => (prev === 'click' ? null : 'click'))}
                    >
                        <Badge badgeContent={newNotifications.length} color="error">
                            <NotificationsIcon sx={{ color: theme.palette.nav.icon }} />
                        </Badge>
                    </IconButton>
                </Tooltip>
            </div>
        </ClickAwayListener>
    );
};

const NotificationCard: React.FC<{
    notifications: NotesNotification[];
}> = (props) => {
    const mutation = useMarkNotesNotificationAsRead();
    const latest = props.notifications.filter((v) => v.unread === true).map((v) => v.createdAt)[0];
    useEffect(() => {
        return () => {
            if (latest) {
                mutation.mutate(latest);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Card
            square={false}
            sx={{
                maxWidth: '360px',
                maxHeight: '300px',
                overflow: 'scroll',
                marginTop: 1,
            }}
        >
            <CardContent>
                <Typography textAlign="center" variant="body2" color="primary">
                    この一週間の通知
                </Typography>
            </CardContent>
            <Divider />
            {props.notifications.map((v, i) => (
                <Box key={`${v.articleId}-${v.talentId}`}>
                    <NotificationCardItem notification={v} />
                    {i !== props.notifications.length - 1 && <Divider />}
                </Box>
            ))}
        </Card>
    );
};

const NotificationCardItem: React.FC<{
    notification: NotesNotification;
}> = (props) => {
    const v = props.notification;
    const theme = useTheme();
    const { me } = useMeContext();
    const { currentDayjs } = useCurrentTimeContext();
    const { talents } = useTalentsContext();
    const talent = findById(v.talentId, talents);
    const maybeArticles = useAuthorsNote(me.hitonowaId);
    const daysAgo = currentDayjs.diff(v.createdAt, 'day');
    const hoursAgo = currentDayjs.diff(v.createdAt, 'hour');
    const when = (() => {
        if (daysAgo) {
            return `${daysAgo}日前`;
        }
        if (hoursAgo) {
            return `${hoursAgo}時間前`;
        }
        return 'ついさっき';
    })();
    const action = (() => {
        const type = v.type;
        switch (type) {
            case 'like':
                return 'をいいね！しました';
            case 'comment':
                return 'にコメントしました';
            default:
                throw new ApplicationError(`unknown notification type: ${String(type)}`);
        }
    })();
    return (
        <CardLink to={getArticleUrl(v.articleId, me.hitonowaId, true)}>
            <CardContent>
                <WaitQuery query={maybeArticles}>
                    {({ data }) => {
                        const article = findById(v.articleId, data.articles);
                        return (
                            <FlexBox flexWrap="nowrap" gap={1}>
                                <TalentAvatar size="medium" talent={talent} />
                                <Box>
                                    <Typography variant="body2">
                                        {fullName(talent)}さんが「{article.title}」{action}
                                    </Typography>
                                    <FlexBox gap={0.5}>
                                        <Typography
                                            variant="caption"
                                            sx={{ color: theme.palette.primary.main }}
                                        >
                                            {when}
                                        </Typography>
                                        {v.unread === true && <FiberNewIcon color="primary" />}
                                    </FlexBox>
                                </Box>
                            </FlexBox>
                        );
                    }}
                </WaitQuery>
            </CardContent>
        </CardLink>
    );
};

const You: React.FC = () => {
    const { me } = useMeContext();
    return (
        <RouterLink
            to={me.hitonowaId ? getIamUrl(me.hitonowaId) : getTalentUrl(me.employment.employeeCode)}
        >
            <FlexBox flexWrap="nowrap">
                <TalentAvatar size="medium" talent={me} />
                <Box
                    pl={1}
                    sx={(theme) => ({ color: theme.palette.nav.text, whiteSpace: 'nowrap' })}
                >
                    <Typography variant="body2">{me.employment.employeeCode}</Typography>
                    <Typography variant="body2">{fullName(me)}</Typography>
                </Box>
            </FlexBox>
        </RouterLink>
    );
};
