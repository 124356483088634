import { DialogContent, DialogTitle, Typography } from '@mui/material';
import { Talent, TransferTalentRequest } from '@spec/Talent';
import React, { useState } from 'react';
import { Form, Strict, useForm, useFormDate, useFormNumber } from '../../../../lib/Form';
import { required } from '../../../../lib/Form/Validators';
import { useTransferTalent } from '../../../../queries/talent';
import { ActionButton } from '../../../ActionButtons';
import { DateInput } from '../../../DateInput';
import { DialogActionButtons, DialogRow, StableDialog } from '../../../StableDialog';
import { TeamSelector } from '../../../TeamSelector';
import { InputIcon } from '../../../../components/Icons';

interface Elements {
    teamId: number | null;
    transferredAt: Date | null;
}

const guard = (v: Elements): v is Strict<Elements> => v.teamId !== null && v.transferredAt !== null;

const toTransferTalentRequest = (form: Form<Elements>): TransferTalentRequest => {
    const args = form.serialize();
    if (!guard(args)) {
        throw Error('invalid parameters');
    }
    return args;
};

export const TransferTalent: React.FC<{ talent: Talent }> = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const form = useForm<Elements>({
        teamId: useFormNumber(null, [required]),
        transferredAt: useFormDate(null, [required]),
    });
    const mutation = useTransferTalent(props.talent.employment.employeeCode);
    return (
        <>
            <StableDialog noClose open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>従業員の異動</DialogTitle>
                <DialogContent>
                    <Typography>
                        異動させると現在の有効な異動履歴は異動日の前日ですべて離任扱いになります。
                    </Typography>
                    <DialogRow label="新しい所属">
                        <TeamSelector teamId={form.teamId} required />
                    </DialogRow>
                    <DialogRow label="異動日">
                        <DateInput formDate={form.transferredAt} />
                    </DialogRow>
                </DialogContent>
                <DialogActionButtons
                    form={form}
                    startIcon={<InputIcon />}
                    submitLabel="異動させる"
                    cancelLabel="キャンセル"
                    errorMessage="異動に失敗しました"
                    onSubmit={() => mutation.mutateAsync(toTransferTalentRequest(form))}
                    closeDialog={() => setDialogOpen(false)}
                />
            </StableDialog>
            <ActionButton startIcon={<InputIcon />} onClick={() => setDialogOpen(true)}>
                この従業員を異動させる
            </ActionButton>
        </>
    );
};
