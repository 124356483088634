import { TabContext, TabList } from '@mui/lab';
import { Badge, Stack, Tab } from '@mui/material';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useUnscreenedTalentCount } from '../../queries/profileImage';
import { RoutingPattern } from '../../Routes';
import { AccountBoxIcon, HistoryIcon } from '../Icons';
import { PageTitle } from '../PageTitle';
import { RequirePrivilege } from '../RequirePrivilege';
import { ContextProvider } from './Context';

export const Layout = () => {
    const location = useLocation();
    const path = location.pathname.replace(RoutingPattern.profileImageScreening, '').split('/')[1];
    const navigate = useNavigate();
    const unscreenedTalentCount = useUnscreenedTalentCount();
    return (
        <RequirePrivilege fn={(grants) => grants.screeningProfileImage}>
            <PageTitle icon={AccountBoxIcon} title="プロフィール画像の審査" />
            <TabContext value={path ?? 'unscreened'}>
                <TabList
                    variant="fullWidth"
                    onChange={(_, value) => {
                        const next = value === 'unscreened' ? '' : `/${value}`;
                        navigate(`${RoutingPattern.profileImageScreening}${next}`);
                    }}
                >
                    <Tab
                        value="unscreened"
                        label="審査待ちのプロフィール画像"
                        icon={
                            <Badge badgeContent={unscreenedTalentCount} color="error">
                                <HistoryIcon />
                            </Badge>
                        }
                        iconPosition="start"
                    />
                    <Tab
                        value="history"
                        label="過去の審査履歴"
                        icon={<HistoryIcon />}
                        iconPosition="start"
                    />
                </TabList>
            </TabContext>
            <ContextProvider>
                <Stack mt={2} spacing={2}>
                    <Outlet />
                </Stack>
            </ContextProvider>
        </RequirePrivilege>
    );
};
