import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import type { ProfileImage } from '@spec/profileImage';
import type { Talent } from '@spec/Talent';
import { useState } from 'react';
import { fullName } from '../../domains/Talent';
import { FlexBox } from '../FlexBox';
import { ClearIcon } from '../Icons';
import { PreviewAvatar } from './PreviewAvatar';

export const RejectAllButton = (props: {
    talent: Talent;
    images: ProfileImage[];
    disabled: boolean;
    onSubmit: () => void;
}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const hasSelfie = props.images.some((v) => v.talentId === v.uploadTalentId);
    return (
        <Box>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>プロフィール画像の審査</DialogTitle>
                <DialogContent>
                    {fullName(props.talent)}さんの審査待ちの画像{props.images.length}
                    枚をすべて不採用にしますか？
                    <FlexBox mt={1} mb={2} gap={2}>
                        {props.images.map((image) => (
                            <PreviewAvatar key={image.id} size="large" image={image} />
                        ))}
                    </FlexBox>
                    {hasSelfie ? (
                        <Alert severity="warning">
                            <AlertTitle>本人に不採用のSlack通知が届きます</AlertTitle>
                            <Typography variant="body2">
                                本人がアップロードした画像が含まれている場合は審査結果をSlack通知しています。
                            </Typography>
                        </Alert>
                    ) : (
                        <Alert severity="info">
                            <AlertTitle>審査結果は本人にはSlack通知されません</AlertTitle>
                            <Typography variant="body2">
                                本人がアップロードした画像が含まれている場合のみ審査結果をSlack通知しています。
                            </Typography>
                        </Alert>
                    )}
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button
                        onClick={() => setDialogOpen(false)}
                        size="medium"
                        variant="outlined"
                        tabIndex={2}
                    >
                        キャンセル
                    </Button>
                    <Box flexGrow={1} textAlign="right">
                        <Button
                            onClick={() => {
                                props.onSubmit();
                                setDialogOpen(false);
                            }}
                            size="medium"
                            variant="contained"
                            color="primary"
                            startIcon={<ClearIcon />}
                            disabled={props.disabled}
                            tabIndex={1}
                        >
                            すべて不採用にする
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Button
                variant="outlined"
                color="primary"
                startIcon={<ClearIcon />}
                disabled={props.disabled}
                onClick={() => setDialogOpen(true)}
            >
                すべて不採用にする
            </Button>
        </Box>
    );
};
