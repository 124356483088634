import { ReactNode } from 'react';
import { createFilterContext } from '../FilterForm';
import { asScreeningStatus, type ScreeningStatus } from './domains';

export interface FilterCondition {
    teamId: number | null;
    keyword: string;
    employmentStatus: string[];
    employeeCode: string;
    status: ScreeningStatus | null;
}

const filterKeys: Record<keyof FilterCondition, string> = {
    teamId: 'teamId',
    keyword: 'word',
    employmentStatus: 'e',
    employeeCode: 'ec',
    status: 'status',
};

const filterContext = createFilterContext<FilterCondition>({
    filterKeys,
    initialize: (params) => ({
        teamId: (() => {
            const baseTeamId = Number(params.get(filterKeys.teamId));
            return baseTeamId > 0 ? baseTeamId : null;
        })(),
        keyword: params.get(filterKeys.keyword) ?? '',
        employmentStatus: (() => {
            const employmentStatus = params.get(filterKeys.employmentStatus);
            return employmentStatus ? employmentStatus.split('-') : [];
        })(),
        employeeCode: params.get(filterKeys.employeeCode) ?? '',
        status: asScreeningStatus(params.get(filterKeys.status) ?? ''),
    }),
    serialize: (condition) => ({
        teamId: (() => {
            if (condition.teamId === null) {
                return undefined;
            }
            return `${condition.teamId}`;
        })(),
        keyword: condition.keyword || undefined,
        employmentStatus:
            condition.employmentStatus.length > 0
                ? condition.employmentStatus.join('-')
                : undefined,
        employeeCode: condition.employeeCode || undefined,
        status: condition.status ?? undefined,
    }),
});

export const useFilterFormContext = filterContext.useFilterContext;

export const ContextProvider = (props: { children: ReactNode }) => {
    return <filterContext.Provider>{props.children}</filterContext.Provider>;
};
