import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { reportError } from '../../Errors';
import { BlockIcon } from '../Icons';
import { useGateway } from '../../stores/Gateway';
import { useFirebaseUserContext } from '../Context';
import { IconCircle } from '../IconCircle';
import { ExternalLink } from '../RouterLink';
import { ErrorContent } from '../WaitLoading';
import { NoMenuLayout } from '../layout/NoMenuLayout';

export const RequirePermission: React.FC<React.PropsWithChildren<unknown>> = () => {
    const gateway = useGateway();
    const { firebaseUser } = useFirebaseUserContext();
    return (
        <NoMenuLayout>
            <Box textAlign="center">
                <IconCircle icon={BlockIcon} />
                <Typography mt={2} variant="h6">
                    ログインが許可されていません
                </Typography>
                <Typography mt={3}>このメールアドレスでは利用できません。</Typography>
                <Typography mt={2} sx={{ fontWeight: 700 }}>
                    {firebaseUser.email ?? ''}
                </Typography>
                <Typography mt={2}>別のアカウントでログインするには、</Typography>
                <Typography mt={0.5}>
                    <ExternalLink href="https://google.com/">https://google.com/</ExternalLink>
                    からアカウントを追加してください。
                </Typography>
                <Box mt={6}>
                    <Button size="large" variant="contained" onClick={() => gateway.signOut()}>
                        ログアウト
                    </Button>
                </Box>
            </Box>
        </NoMenuLayout>
    );
};

export const NotRegistered: React.FC<React.PropsWithChildren<unknown>> = () => {
    const gateway = useGateway();
    const { firebaseUser } = useFirebaseUserContext();
    const email = firebaseUser.email;
    if (email === null) {
        reportError(Error('Empty email for firebaseUser'));
        return <ErrorContent />;
    }
    return (
        <NoMenuLayout>
            <Box textAlign="center">
                <Box mb={2}>
                    <IconCircle icon={BlockIcon} />
                </Box>
                <Box mb={3}>
                    <Typography variant="h6">ログインが許可されていません</Typography>
                </Box>
                {/^c[abcd]/.test(email) ? (
                    <>
                        <Box mb={1}>
                            <Typography>入社情報の反映が遅れている可能性があります。</Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography>
                                あまりに遅い場合はお手数ですが、人事までお問い合わせください。
                            </Typography>
                        </Box>
                    </>
                ) : (
                    <Box mb={2}>
                        <Typography>
                            ご利用の社員番号は現在サービスの提供対象外となっています。
                        </Typography>
                    </Box>
                )}
                <Box mb={6}>
                    <Typography sx={{ fontWeight: 700 }}>{email}</Typography>
                </Box>
                <Box>
                    <Button size="large" variant="contained" onClick={() => gateway.signOut()}>
                        ログアウト
                    </Button>
                </Box>
            </Box>
        </NoMenuLayout>
    );
};
