import { Box, MenuItem, TextField, Typography } from '@mui/material';
import { Team, TeamId } from '@spec/Organization';
import React from 'react';
import { generateBreadcrumbs } from '../domains/Organization';
import { useTeamsContext } from './Context';
import { FlexBox } from './FlexBox';

// Use this as a default teamId
export const ROOT_TEAM_ID = 3;

export const TeamDrill: React.FC<{
    teamId: TeamId | null;
    setTeamId: (v: TeamId | null) => void;
    filterTeam?: (v: Team) => boolean;
}> = (props) => {
    const { availableTeams } = useTeamsContext();
    const teamId = props.teamId ?? getRootTeamId(availableTeams);
    const bread = props.teamId === null ? [] : generateBreadcrumbs(props.teamId, availableTeams);
    const children = availableTeams.filter((v) => v.parentId === teamId);
    return (
        <FlexBox>
            {bread.map((v) => {
                return (
                    <Selector
                        key={v.id}
                        parentId={v.parentId}
                        teamId={v.id}
                        setTeamId={props.setTeamId}
                        filterTeam={props.filterTeam}
                    />
                );
            })}
            {children.length > 0 && (
                <Selector
                    parentId={teamId}
                    teamId={null}
                    setTeamId={props.setTeamId}
                    filterTeam={props.filterTeam}
                />
            )}
        </FlexBox>
    );
};

const Selector: React.FC<{
    parentId: TeamId | null;
    teamId: TeamId | null;
    setTeamId: (v: TeamId | null) => void;
    filterTeam?: (v: Team) => boolean;
}> = (props) => {
    const { availableTeams } = useTeamsContext();
    const filterFn = props.filterTeam ?? (() => true);
    const filteredTeams = availableTeams.filter(filterFn);
    const teams = filteredTeams.filter((v) => v.parentId === props.parentId);
    if (teams.length === 0) {
        return null;
    }
    if (isSingleRoot(props.teamId, availableTeams)) {
        return null;
    }
    const hasChildren = filteredTeams.some((v) => v.parentId === props.teamId);
    const parentId = isSingleRoot(props.parentId, availableTeams) ? null : props.parentId;
    return (
        <FlexBox>
            <TextField
                select
                value={props.teamId ?? parentId ?? '-'}
                onChange={(e) => {
                    const v = Number(e.target.value);
                    props.setTeamId(Number.isNaN(v) ? null : v);
                }}
            >
                <MenuItem value={parentId ?? '-'}>指定なし</MenuItem>
                {teams.map((v) => (
                    <MenuItem key={v.id} value={v.id}>
                        {v.name}
                    </MenuItem>
                ))}
            </TextField>
            {props.teamId && hasChildren && (
                <Box mx={1}>
                    <Typography>›</Typography>
                </Box>
            )}
        </FlexBox>
    );
};

/*
 * Return the id of the root team,
 * return null if there are multiple roots.
 */
const getRootTeamId = (teams: Team[]): TeamId | null => {
    const rootTeams = teams.filter((team) => team.parentId === null);
    return rootTeams.length === 1 ? rootTeams[0].id : null;
};

const isSingleRoot = (teamId: TeamId | null, teams: Team[]): boolean => {
    const rootTeamId = getRootTeamId(teams);
    if (rootTeamId === null) {
        return false;
    }
    return teamId === rootTeamId;
};
