import { Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { RoutingPattern } from '../../Routes';
import { LinkButton } from '../ActionButtons';
import { NavigateBeforeIcon } from '../Icons';

export const BackToIndex: React.FC = () => {
    const location = useLocation();
    if (
        location.pathname !== RoutingPattern.surveyAdmin &&
        location.pathname.startsWith(RoutingPattern.surveyAdmin)
    ) {
        return (
            <Box my={2}>
                <LinkButton to={RoutingPattern.surveyAdmin} startIcon={<NavigateBeforeIcon />}>
                    サーベイ管理のトップに戻る
                </LinkButton>
            </Box>
        );
    }
    if (location.pathname !== RoutingPattern.todo) {
        return (
            <Box my={2}>
                <LinkButton to={RoutingPattern.survey} startIcon={<NavigateBeforeIcon />}>
                    サーベイのトップに戻る
                </LinkButton>
            </Box>
        );
    }
    return null;
};
