import {
    Box,
    Button,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    useTheme,
    type SvgIconProps,
} from '@mui/material';
import { AnnouncementIcon } from '../Icons';
import type { NotificationEvent, NotificationType } from '@spec/notification';
import React, { useCallback, useMemo, useState } from 'react';
import { reportError } from '../../Errors';
import { buildAnnouncement, deduplicateNotifications } from '../../domains/notification';
import { sortByKey } from '../../lib/ArrayUtils';
import { groupBy } from '../../lib/MapUtils';
import { useMarkNotificationAsRead, useNotifications } from '../../queries/notification';
import { AutorenewIcon, FactCheckIcon, PollIcon } from '../Icons';
import { PageTitle, SubTitle } from '../PageTitle';
import { AnnouncementCard } from './Announcement';

export const AnnouncementDialog: React.FC = () => {
    const notifications = useNotifications();
    if (notifications.isError) {
        reportError(notifications.error);
        return null;
    }
    if (notifications.isPending || notifications.data === undefined) {
        return null;
    }
    return <Content notifications={notifications.data} />;
};

const Content: React.FC<{
    notifications: NotificationEvent[];
}> = (props) => {
    const theme = useTheme();

    const notifications = useMemo(
        () =>
            deduplicateNotifications(
                sortByKey(
                    props.notifications.filter((v) => v.unread === true),
                    'occurredAt'
                ),
                'last'
            ),
        [props.notifications]
    );

    const [open, setOpen] = useState(notifications.length > 0);
    const mutation = useMarkNotificationAsRead();
    const handleClose = useCallback(() => {
        setOpen(false);
        if (notifications.filter((v) => v.unread === true).length > 0) {
            mutation.mutate(Math.max(...notifications.map((v) => v.id)));
        }
    }, [mutation, notifications]);

    if (notifications.length === 0) {
        return null;
    }

    return (
        <Dialog
            open={open}
            slotProps={{
                backdrop: {
                    sx: {
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        backdropFilter: 'blur(4px)',
                    },
                },
            }}
            PaperProps={{
                sx: {
                    backgroundColor: theme.palette.background.default,
                },
            }}
        >
            <DialogTitle sx={{ pb: 0 }}>
                <PageTitle icon={AnnouncementIcon} title="お知らせ" />
            </DialogTitle>
            <DialogContent>
                {[...groupBy(notifications, (v) => v.type)].map(([k, notifications]) => (
                    <Box mb={2} key={k}>
                        <AnnouncementCategory type={k} />
                        <Box mt={2}>
                            {notifications.flatMap(buildAnnouncement).map((v) => (
                                <AnnouncementCard
                                    key={v.id}
                                    announcement={v}
                                    onClose={handleClose}
                                />
                            ))}
                        </Box>
                    </Box>
                ))}
                <Container maxWidth="sm">
                    <Box mt={4} mb={2} textAlign="center">
                        <Button
                            fullWidth
                            size="medium"
                            variant="outlined"
                            color="primary"
                            onClick={handleClose}
                        >
                            閉じる
                        </Button>
                    </Box>
                </Container>
            </DialogContent>
        </Dialog>
    );
};

function AnnouncementCategory(props: { type: NotificationType }) {
    const titles: Record<NotificationType, { icon: React.FC<SvgIconProps>; title: string }> = {
        survey: { icon: PollIcon, title: 'サーベイ' },
        valueFeedback: { icon: AutorenewIcon, title: 'バリューフィードバック' },
        todo: { icon: FactCheckIcon, title: 'やることリスト' },
    };
    const v = titles[props.type];
    return <SubTitle icon={v.icon} title={v.title} />;
}
