import { Alert, AlertTitle, Box, Divider, Typography } from '@mui/material';
import { useMeContext } from '../../../queries/me';
import { LinkButton } from '../../ActionButtons';
import { FlexBox } from '../../FlexBox';
import { CropIcon, ImageIcon } from '../../Icons';
import { PreviewAvatar } from '../../profileImageScreening/PreviewAvatar';
import { ExternalLink } from '../../RouterLink';
import { avatarSize, TalentAvatar } from '../../TalentAvatar';

export const ProfileImageList = () => {
    const { me, profileImage } = useMeContext();
    return (
        <Box>
            {profileImage.lastScreened !== null && <ScreeningAlert />}
            <FlexBox gap={4} alignItems="flex-start">
                <Box>
                    <Typography variant="body2">現在の画像</Typography>
                    <Box mt={0.5}>
                        <TalentAvatar size="xlarge" talent={me} />
                    </Box>
                    <Box mt={1}>
                        <EditButton base="current" />
                    </Box>
                </Box>
                {(profileImage.pending !== null ||
                    profileImage.lastScreened?.isApproved === false) && (
                    <Divider orientation="vertical" flexItem />
                )}
                {profileImage.pending !== null && (
                    <Box>
                        <Typography variant="body2">確認待ちの画像</Typography>
                        <Box mt={0.5}>
                            <PreviewAvatar size="xlarge" image={profileImage.pending} />
                        </Box>
                        <Box mt={1}>
                            <EditButton base="pending" />
                        </Box>
                    </Box>
                )}
                {profileImage.lastScreened?.isApproved === false && (
                    <Box>
                        <Typography variant="body2">不採用になった画像</Typography>
                        <Box mt={0.5}>
                            <PreviewAvatar size="xlarge" image={profileImage.lastScreened} />
                        </Box>
                        <Box mt={1}>
                            <EditButton base="lastScreened" />
                        </Box>
                    </Box>
                )}
            </FlexBox>
            <Box mt={2}>
                <LinkButton
                    variant="outlined"
                    color="primary"
                    size="medium"
                    startIcon={<ImageIcon />}
                    to="profile-image"
                >
                    新しい画像をアップロードする
                </LinkButton>
            </Box>
        </Box>
    );
};

const EditButton = (props: { base: string }) => {
    return (
        <LinkButton
            sx={{ width: avatarSize.xlarge }}
            variant="outlined"
            color="primary"
            startIcon={<CropIcon />}
            to={`profile-image?base=${props.base}`}
        >
            再編集
        </LinkButton>
    );
};

const ScreeningAlert = () => {
    return (
        <Alert severity="warning" sx={{ my: 2 }}>
            <AlertTitle>アップロードされた画像はHRでの確認を経て反映されます</AlertTitle>
            <Typography variant="body2">
                CARUUPEの
                <ExternalLink
                    icon
                    href="https://sites.google.com/cartahd.com/caruupe/%E3%83%9B%E3%83%BC%E3%83%A0/%E4%BA%BA%E4%BA%8B%E5%8A%B4%E5%8B%99/%E3%83%92%E3%83%88%E3%83%8E%E3%83%AF%E3%81%AE%E3%83%97%E3%83%AD%E3%83%95%E3%82%A3%E3%83%BC%E3%83%AB%E7%94%BB%E5%83%8F%E8%A8%AD%E5%AE%9A"
                    sx={{ mx: 0.5 }}
                >
                    ヒトノワのプロフィール画像設定
                </ExternalLink>
                に記載している目安から外れた画像は承認されない場合があります。
            </Typography>
        </Alert>
    );
};
