import { Box } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { RoutingPattern } from '../../Routes';
import { LinkButton } from '../ActionButtons';
import { FlexBox } from '../FlexBox';
import { EditIcon, NavigateBeforeIcon } from '../Icons';
import { exitArticleEditUrl, newPostUrl } from './urls';

export const BackToIndex: React.FC = () => {
    const location = useLocation();
    const path = location.pathname.replace(RoutingPattern.notes, '');
    const ignorePaths = ['', '/liked', '/trend'];
    if (ignorePaths.includes(path)) {
        return null;
    }
    const articleUrl = exitArticleEditUrl(location.pathname);
    if (articleUrl !== null) {
        return (
            <Box my={2}>
                <LinkButton to={articleUrl} startIcon={<NavigateBeforeIcon />}>
                    記事ページに戻る
                </LinkButton>
            </Box>
        );
    }
    return (
        <FlexBox my={2}>
            <Box flexGrow={1}>
                <LinkButton to={RoutingPattern.notes} startIcon={<NavigateBeforeIcon />}>
                    ノートのトップに戻る
                </LinkButton>
            </Box>
            {location.pathname !== newPostUrl && (
                <Box mr={1}>
                    <LinkButton variant="outlined" startIcon={<EditIcon />} to={newPostUrl}>
                        新しい記事を書く
                    </LinkButton>
                </Box>
            )}
        </FlexBox>
    );
};
