import { HowToRegIcon } from '../../../../components/Icons';
import { Box, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Talent } from '@spec/Talent';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useForm, useFormDate } from '../../../../lib/Form';
import { useMeContext } from '../../../../queries/me';
import { setSabbaticalLeaveBasedDate, useSabbaticalLeaves } from '../../../../queries/talent';
import { useGateway } from '../../../../stores/Gateway';
import { ActionButton, ActionContainer } from '../../../ActionButtons';
import { useCurrentTimeContext } from '../../../Context';
import { OptionalDateInput } from '../../../DateInput';
import { DialogActionButtons, DialogRow, StableDialog } from '../../../StableDialog';
import { WaitQuery } from '../../../WaitLoading';

interface Elements {
    basedOn: Date | null;
}

export const SabbaticalLeaveBasedDate: React.FC<{
    talent: Talent;
}> = (props) => {
    const { grants } = useMeContext();
    if (grants.showFullPersonality === false) {
        return null;
    }
    return <Loader talent={props.talent} />;
};

const Loader: React.FC<{
    talent: Talent;
}> = (props) => {
    const maybeSabbaticalLeaves = useSabbaticalLeaves();
    return (
        <WaitQuery query={maybeSabbaticalLeaves}>
            {({ data }) => (
                <BasedDateDialog
                    currentBasedOn={
                        data.baseDates.find((v) => v.talentId === props.talent.id)?.basedOn ?? null
                    }
                    talent={props.talent}
                />
            )}
        </WaitQuery>
    );
};

const BasedDateDialog: React.FC<{
    currentBasedOn: Date | null;
    talent: Talent;
}> = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const form = useForm<Elements>({
        basedOn: useFormDate(props.currentBasedOn),
    });
    const { currentTime } = useCurrentTimeContext();
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return (
        <ActionContainer>
            <Typography variant="body2" color="primary">
                この従業員のボーナス休暇の基準日を変更できます
            </Typography>
            <Box>
                <StableDialog noClose open={dialogOpen} onClose={() => setDialogOpen(false)}>
                    <DialogTitle>ボーナス休暇基準日の設定</DialogTitle>
                    <DialogContent>
                        <Typography mb={2}>
                            ボーナス休暇の算出基準日を個別に設定できます。設定しない場合は入社日から起算されます。
                        </Typography>
                        <DialogRow label="基準日">
                            <OptionalDateInput
                                label="基準日を設定する"
                                formDate={form.basedOn}
                                min={props.talent.joinedAt}
                                max={currentTime}
                            />
                        </DialogRow>
                    </DialogContent>
                    <DialogActionButtons
                        form={form}
                        startIcon={<HowToRegIcon />}
                        submitLabel="基準日を変更する"
                        cancelLabel="キャンセル"
                        errorMessage="基準日の変更に失敗しました"
                        onSubmit={() =>
                            setSabbaticalLeaveBasedDate(
                                gateway,
                                queryClient,
                                props.talent.employment.employeeCode,
                                form.serialize()
                            ).then(() => setDialogOpen(false))
                        }
                        closeDialog={() => setDialogOpen(false)}
                    />
                </StableDialog>
                <ActionButton startIcon={<HowToRegIcon />} onClick={() => setDialogOpen(true)}>
                    ボーナス休暇の基準日を変更する
                </ActionButton>
            </Box>
        </ActionContainer>
    );
};
